
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    message: String,
    messageType: {
      type: String,
      default: 'error'
    }
  }
})
export default class WarningMessage extends Vue {}
