import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UButton = _resolveComponent("UButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_main_page_header = _resolveComponent("main-page-header")!
  const _component_PersonalListContent = _resolveComponent("PersonalListContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_main_page_header, {
      title: "Сотрудники",
      class: "page__title"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, { to: { name: 'personal.add.new' } }, {
          default: _withCtx(() => [
            _createVNode(_component_UButton, {
              label: "Пригласить сотрудника",
              class: "mr-2"
            })
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }),
    _createVNode(_component_PersonalListContent)
  ], 64))
}