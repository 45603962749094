
import { Options, Vue } from 'vue-class-component'
import { UModal, UISelect } from 'unit-uikit'
import { API } from '@/utils/API'
import { formatPrice } from '@/utils/helpers'
import Loader from '@/components/Loader/Loader.vue'
import { useOrderStore } from '@/stores/order'

@Options({
  name: 'order',
  components: {
    Loader,
    UISelect,
    UModal
  },
  data() {
    return {
      orderStore: useOrderStore(),
      loaded: false,
      showCancelPopup: false,
      showPopupAtCreated: true,
      signedError: false,
      cannotCancelOrder: false,
      order: {},
      orderStatuses: [
        {
          id: 'active',
          name: 'Активный'
        },
        {
          id: 'cancelled',
          name: 'Отменен'
        }
      ],
      pageReady: false
    }
  },
  created() {
    this.orderStore.fetchOrder(this.$route.params.id)
      .then((order: any) => {
        this.order = order
        if (this.order?.status === 'cancelled') {
          this.showCancelPopup = false
          this.showPopupAtCreated = false
        }
        if (order?.status === 'finished') {
          this.orderStatuses.push({
            id: 'finished',
            name: 'Завершён'
          })
        }
        this.loaded = true
      })
      .catch((e: any) => {
        console.error(e)
      })
    this.showCancelPopup = false
  },
  mounted() {
    this.pageReady = true
    this.showCancelPopup = false
  },
  methods: {
    getEquipment() {
      if (this.order.equipment) {
        if (Array.isArray(this.order.equipment)) {
          return this.order.equipment.length ? this.order.equipment.map((item: any) => item.name).join(', ') : 'Нет'
        }
        return this.order.equipment || 'Нет'
      }
      return 'Нет'
    },
    getJobCerts() {
      if (this.order.job_certs_requirements) {
        if (Array.isArray(this.order.job_certs_requirements)) {
          return this.order.job_certs_requirements.length
            ? this.order.job_certs_requirements.map((item: any) => item.name).join(', ')
            : 'Нет'
        }
        return this.order.job_certs_requirements || 'Нет'
      }
      return 'Нет'
    },
    getSingleOrderData(id: any) {
      this.orderStore.fetchOrder(id)
        .then((order: any) => {
          this.order = order
          this.loaded = true
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    formatDateTime(date: string) {
      if (date) {
        return date.split('-').reverse().join('.')
      }
    },
    getOrderEquipment(equipment: any) {
      if (equipment.length === 0) return 'Нет'
      return equipment
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatTemplateName(name: string) {
      if (name) {
        const chars = name.split('')
        chars[0] = chars[0].toUpperCase()
        return chars.join('').replaceAll('_', ' ')
      }
      return ''
    },
    cancelOrder(id: any) {
      this.axios
        .get(API.GET_ORDER_DOCUMENTS(id))
        .then((response: any) => {
          const data = response.data
          const result = data.some((item: any) => {
            const value = [item.agreement_status[0].status, item.task_order_status[0].statusOptions].includes('SIGNED')
            return value
          })

          if (!result) {
            this.signedError = false
            this.axios
              .patch(API.DELETE_SINGLE_ORDER(id), { status: 'cancelled' })
              .then(() => {
                this.getSingleOrderData(this.$route.params.id)
                this.showCancelPopup = false
              })
              .then((res: any) => console.log(res))
              .catch((e: any) => {
                this.signedError = true
                console.error(e)
              })
          } else {
            this.signedError = true
          }
        })
        .catch(() => {
          this.cannotCancelOrder = true
        })
    },
    closePopup() {
      this.order.status = 'active'
      this.signedError = false
      this.showCancelPopup = false
    },
    closeErrorPopup() {
      this.showCancelPopup = false
      this.cannotCancelOrder = false
      this.order.status = 'active'
    }
  },
  watch: {
    'order.status'(val) {
      if (val === 'cancelled') {
        this.showCancelPopup = true
      }
    }
  }
})
export default class OrderInfo extends Vue {}
