
import { Options, Vue } from 'vue-class-component'

import FinanceOrdersList from '@/views/Finance/Components/OrdersList.vue'
import SubordersList from '@/views/Finance/Components/SubordersList.vue'

import { USwitch } from 'unit-uikit'

@Options({
  name: 'FinanceOrders',
  data() {
    return {
      showSuborders: false
    }
  },
  components: {
    USwitch
  },
  computed: {
    currentComponent() {
      return this.showSuborders ? SubordersList : FinanceOrdersList
    }
  }
})
export default class FinanceOrders extends Vue {}
