
import { defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import moment from 'moment'
import { UButton, UNotifications } from 'unit-uikit'
import { EOrderStatus, ESuborderStatus } from '@/types/api-values'

export default defineComponent({
  name: 'Notifications',
  components: {
    UNotifications,
    UButton
  },
  props: ['showPopup'],
  data() {
    return {
      loading: false,
      notifications: [] as any,
      counter: 0,
      notificationsNextPage: '',
      notificationsViewedNextPage: '',
      scrollData: {
        count: 0,
        size: 10,
        page: 1
      }
    }
  },
  created() {
    this.getNotificationsList()
  },
  methods: {
    readAll() {
      axios
        .post(API.READ_ALL_NOTIFICATIONS(), {})
        .then(() => {
          this.notifications = []
          this.getNotificationsList()
          this.$emit('change-counter')
        })
        .catch((e) => {
          console.error(e)
        })
    },
    showPayBtn(notification: any) {
      const correctType = notification?.type === 'suborder_status_awaiting_payments'
      const status = notification?.payload?.order_status
      const suborderStatus = notification?.payload?.suborder_status
      const correctStatus = status !== EOrderStatus.finished && status !== EOrderStatus.cancelled
      return correctType && correctStatus && suborderStatus !== ESuborderStatus.finished
    },
    async loadNotifications() {
      if (this.notifications.length < this.scrollData.count) {
        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}`

        if (this.notificationsNextPage) {
          const response = await axios.get(API.NOTIFICATIONS + queryString)

          const data = response.data
          if (data.results && data.count) {
            this.scrollData.count = data.count || 0
            const result: any = data.results
            this.notifications.push(...result)
          }
        }
        if (this.notificationsViewedNextPage) {
          const response = await axios.get(API.VIEWED_NOTIFICATIONS + queryString)

          const data = response.data
          if (data.results && data.count) {
            this.scrollData.count = data.count || 0
            const result: any = data.results
            this.notifications.push(...result)
          }
        }
      }
    },
    getNotificationsList() {
      this.loading = true
      axios
        .get(API.NOTIFICATIONS + `?page_size=${this.scrollData.size}`)
        .then((res) => {
          this.scrollData.count += res.data.count || 0
          this.notificationsNextPage = res.data.next || ''
          this.counter = res.data.count
          this.notifications.push(...res.data.results)
          this.loading = false
        })
        .catch((e) => {
          console.error(e)
          this.loading = false
        })
      axios
        .get(API.VIEWED_NOTIFICATIONS + `?page_size=${this.scrollData.size}`)
        .then((res) => {
          this.scrollData.count += res.data.count || 0
          this.notificationsViewedNextPage = res.data.next || ''
          this.notifications.push(...res.data.results)
          this.loading = false
        })
        .catch((e) => {
          console.error(e)
          this.loading = false
        })
    },
    getNotificationDate(date: any) {
      moment.locale('ru')
      const momentDate = moment(new Date(date))
      return `${momentDate.fromNow()} в ${momentDate.format('hh:mm:ss')}`
    },
    readNotification(id: string | number, isViewed: boolean) {
      if (!isViewed) {
        axios
          .patch(API.READ_NOTIFICATION(id), { viewed: true })
          .then(() => {
            this.notifications = []
            this.getNotificationsList()
            this.$emit('change-counter')
          })
          .catch((e) => {
            console.error(e)
          })
      }
    },
    getOrderInfo(notification: any) {
      if (notification.payload.suborder_id) {
        return `Заказ №${notification.payload.suborder_id} `
      } else {
        return ''
      }
    }
  },
  computed: {
    sortedNotifications() {
      let notificationsSortedByDate = this.notifications.sort((a: any, b: any) => moment(b.last_updated).diff(moment(a.last_updated)))
      let notificationsSortedByDateAndViwedStatus = notificationsSortedByDate.sort((a: any, b: any) => Number(a.viewed) - Number(b.viewed))
      return notificationsSortedByDateAndViwedStatus
    }
  }
})
