import { SERVER_COUNTRIES } from '@/utils/consts'

export function showPassportFieldByCountry(field: string, country: string) {
  switch (field) {
    case 'start_work_notification':
    case 'end_work_notification':
      return [
        SERVER_COUNTRIES.KAZAKHSTAN,
        SERVER_COUNTRIES.ARMENIA,
        SERVER_COUNTRIES.KYRGYZSTAN,
        SERVER_COUNTRIES.UKRAINE,
        SERVER_COUNTRIES.DNR,
        SERVER_COUNTRIES.LNR
      ].includes(country)
    case 'passportType':
      if (
        country === SERVER_COUNTRIES.RUSSIA ||
        country === SERVER_COUNTRIES.ARMENIA ||
        country === SERVER_COUNTRIES.BELARUS ||
        country === SERVER_COUNTRIES.UKRAINE ||
        country === SERVER_COUNTRIES.DNR ||
        country === SERVER_COUNTRIES.LNR
      ) {
        return false
      }
      return true
    case 'subdivision_code':
    case 'passport_series':
      return [SERVER_COUNTRIES.RUSSIA, SERVER_COUNTRIES.DNR].includes(country)
    case 'passport_number':
      return true
    case 'passport_id_number':
      return ![SERVER_COUNTRIES.RUSSIA, SERVER_COUNTRIES.DNR, SERVER_COUNTRIES.LNR, SERVER_COUNTRIES.ARMENIA].includes(country)
    case 'vnzh':
      return SERVER_COUNTRIES.RUSSIA !== country

    default:
      return true
  }
}

const COUNTRIES: any = {
  [SERVER_COUNTRIES.RUSSIA]: 'РФ',
  [SERVER_COUNTRIES.BELARUS]: 'Республика Беларусь',
  [SERVER_COUNTRIES.ARMENIA]: 'Армения',
  [SERVER_COUNTRIES.KAZAKHSTAN]: 'Казахстан',
  [SERVER_COUNTRIES.KYRGYZSTAN]: 'Киргизия',
  [SERVER_COUNTRIES.UKRAINE]: 'Украина',
  [SERVER_COUNTRIES.DNR]: 'Донецкая Народная Республика',
  [SERVER_COUNTRIES.LNR]: 'Луганская Народная Республика'
}

export const COUNTRI_CODES: any = {
  [SERVER_COUNTRIES.RUSSIA]: 'RU',
  [SERVER_COUNTRIES.BELARUS]: 'BY',
  [SERVER_COUNTRIES.ARMENIA]: 'AM',
  [SERVER_COUNTRIES.KAZAKHSTAN]: 'KZ',
  [SERVER_COUNTRIES.KYRGYZSTAN]: 'KG',
  [SERVER_COUNTRIES.UKRAINE]: 'UA',
  [SERVER_COUNTRIES.DNR]: 'DPR',
  [SERVER_COUNTRIES.LNR]: 'LPR'
}

export default COUNTRIES
