
import { Options, Vue } from 'vue-class-component'

import AuthLayout from '@/views/Registration/AuthLayout.vue'
import { UButton } from 'unit-uikit'
import { useAuthStore } from '@/stores/auth'

@Options({
  components: {
    AuthLayout,
    UButton
  },
  methods: {
    logout() {
      const { logout } = useAuthStore()
      logout()
    }
  }
})
export default class PageNotVerifiedCompany extends Vue {}
