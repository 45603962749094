
import { Options, Vue } from 'vue-class-component'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { UTabs } from 'unit-uikit'

@Options({
  name: 'AddWorkerWays',
  components: {
    MainPageHeader,
    UTabs
  },
  data() {
    return {
      tabs: [
        {
          routeName: 'personal.add.new',
          label: 'Приглашение сотрудника'
        },
        {
          routeName: 'personal.add.invited',
          label: 'Список приглашений'
        }
      ]
    }
  }
})
export default class AddWorkerWays extends Vue {}
