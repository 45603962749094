
import AddEditObject from '@/components/Objects/AddEditObjects.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    AddEditObject
  }
})
export default class EditObject extends Vue {
}
