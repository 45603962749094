
import { Options, Vue } from 'vue-class-component'
import { UCard, UInput } from 'unit-uikit'
import { API } from '@/utils/API'
import Loader from '@/components/Loader/Loader.vue'
import { formatPrice } from '@/utils/helpers'

@Options({
  name: 'AddOrderExtended',
  components: {
    Loader,
    UCard,
    UInput
  },
  props: {
    order: { type: Object },
    budget: { type: Object },
    companyObject: { type: Object },
    specialErrors: { type: Object }
  },
  data() {
    return {
      companyInfo: null,
      // store budet inputs states
      budgetInputsBudgetPlusTaxes: false,
      budgetInputsOneWorkerSum: false,
      budgetInputsAllWorkersSum: false,
      // end states
      allWorkersSum: null,
      oneWorkerSum: null,
      budgetPlusTaxes: null
    }
  },
  methods: {
    formatPercentage(value: any) {
      if (value) {
        return ((value * 100).toFixed(2) + '').replace('.', ',')
      }
      return '0,00'
    },
    formatPrice,
    getFreeBalance() {
      return this.companyInfo.company_deposit - this.companyInfo.total_reserve
    },
    countBlurWorkerAmount() {
      if (this.budget.workersAmount === '') {
        this.budget.workersAmount = 1
      }
    }
  },
  created() {
    const objectId = this.order.object
    this.axios
      .get(API.GET_FINANCIAL_INFO(objectId))
      .then((response: any) => {
        this.companyInfo = response.data
        this.companyObject.info = response.data

        this.allWorkersSum = this.budget.allWorkersSum || null
        this.oneWorkerSum = this.budget.oneWorkerSum || null
        this.budgetPlusTaxes = this.budget.budgetPlusTaxes || null
        if (this.budgetPlusTaxes && !this.allWorkersSum && !this.oneWorkerSum) {
          const clearBudget =
            this.budget.budgetPlusTaxes * (1 - this.companyInfo.unitpay_commission) * (1 - this.companyInfo['self-employed_tax'])

          // Всем исполнителям на руки
          const allWorkersSum = clearBudget || 0
          this.allWorkersSum = allWorkersSum.toFixed(2)
          // Исполнтелю на руки
          this.oneWorkerSum = (allWorkersSum / this.budget.workersAmount).toFixed(2)
        }
      })
      .catch((e: any) => {
        console.error(e)
      })
  },
  computed: {
    isOverDraftCompany() {
      const _value = this.budget.budgetPlusTaxes - (this.companyInfo.company_deposit - this.companyInfo.total_reserve) > 0
      this.specialErrors.isOverDraftCompany = _value
      return _value
    },
    isOverDraft() {
      this.specialErrors.overdraft = this.budget.budgetPlusTaxes - this.freeBalance > 0
      return this.budget.budgetPlusTaxes - this.freeBalance > 0
    },
    workerWithTax() {
      const amount = +this.clearSoloBudget + +this.workerTax
      const workerWithTax = amount ? amount.toFixed(2) : 0
      this.budget.workerWithTax = workerWithTax
      return workerWithTax
    },
    outcomeTax() {
      // “Итого на исполнителя (налог + %)”=((Исполнителю “на руки“)/(0,94))/(0,9525) - ставка комиссии юнит (4,75% или иная привязана к профилю заказчика)
      if (this.companyInfo && this.clearSoloBudget) {
        // const tax = this.clearSoloBudget / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)
        const tax = this.budget.budgetPlusTaxes / this.budget.workersAmount
        const fullTaxes = tax ? tax.toFixed(2) : 0
        this.budget.fullTaxes = fullTaxes
        return fullTaxes
      }
      return 0
    },
    fullOutcomeTax() {
      // (Итого налог + % на всех)=(Всего налогов самозанятых 6%)+(Всего комиссия платформы 4,75%)
      return (+this.allWorkersTaxes + +this.finalPlatformCommision).toFixed(2)
    },
    platformComission() {
      // (Комиссия платформы 4,75%)=“Итого на исполнителя (налог + %)”*0,0475
      if (this.outcomeTax && this.companyInfo) {
        const commision = this.outcomeTax * this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    finalPlatformCommision() {
      // (Всего комиссия платформы 4,75%)=(((Всем исполнителям “на руки”)/(0,94))/(0,9525))*0,0475
      if (this.companyInfo && this.clearBudget) {
        const commision =
          (this.clearBudget / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)) *
          this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    workerTax() {
      // (Налог самозанятого 6%)=((Исполнителю “на руки“)/0,94)*0,06
      if (this.companyInfo && this.clearSoloBudget) {
        const tax = (this.clearSoloBudget / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return tax ? tax.toFixed(2) : 0
      }
      return 0
    },
    allWorkersTaxes() {
      // (Всего налогов самозанятых 6%)=((Всем исполнителям “на руки”)/0,94)*0,06
      if (this.clearBudget) {
        const taxes = (this.clearBudget / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return taxes ? taxes.toFixed(2) : 0
      }
      return 0
    },
    freeBalance() {
      return this.companyInfo ? this.companyInfo.object_budget - this.companyInfo.object_reserve : 0
    },
    clearBudget() {
      return this.budget.allWorkersSum ? (this.budget.allWorkersSum + '').replaceAll(' ', '').replaceAll(',', '.') : 0
    },
    clearSoloBudget() {
      return this.budget.oneWorkerSum ? (this.budget.oneWorkerSum + '').replaceAll(' ', '').replaceAll(',', '.') : 0
    }
  },
  watch: {
    allWorkersSum(value) {
      if (this.budgetInputsAllWorkersSum) {
        this.budgetInputsAllWorkersSum = false
        return
      }
      const clearValue = parseFloat((value + '').replaceAll(' ', '').replaceAll(',', '.'))
      if (+this.budget.allWorkersSum === +clearValue) {
        return
      }
      this.budgetInputsBudgetPlusTaxes = true
      this.budgetInputsOneWorkerSum = true
      this.budgetInputsAllWorkersSum = false

      this.budget.allWorkersSum = clearValue
    },
    oneWorkerSum(value) {
      if (this.budgetInputsOneWorkerSum) {
        this.budgetInputsOneWorkerSum = false
        return
      }
      const clearValue = parseFloat((value + '').replaceAll(' ', '').replaceAll(',', '.'))
      if (+this.budget.oneWorkerSum === clearValue) {
        return
      }
      this.budgetInputsBudgetPlusTaxes = true
      this.budgetInputsOneWorkerSum = false
      this.budgetInputsAllWorkersSum = true

      this.budget.oneWorkerSum = clearValue
    },
    budgetPlusTaxes(value) {
      if (this.budgetInputsBudgetPlusTaxes) {
        this.budgetInputsBudgetPlusTaxes = false
        return
      }
      const clearValue = parseFloat((value + '').replaceAll(' ', '').replaceAll(',', '.'))
      if (+this.budget.budgetPlusTaxes === clearValue) {
        return
      }
      this.budgetInputsBudgetPlusTaxes = false
      this.budgetInputsOneWorkerSum = true
      this.budgetInputsAllWorkersSum = true

      this.budget.budgetPlusTaxes = clearValue
    },
    'budget.oneWorkerSum'() {
      if (this.budgetInputsOneWorkerSum) {
        const clearValue = (this.budget.oneWorkerSum + '').replaceAll('.', ',')
        this.oneWorkerSum = clearValue || 0
        return
      }

      if (this.companyInfo) {
        // Всем исполнителям на руки
        this.budget.allWorkersSum = (this.budget.oneWorkerSum * this.budget.workersAmount).toFixed(2)
        // Бюджет заказа
        const budget = this.budget.allWorkersSum / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)
        this.budget.budgetPlusTaxes = budget.toFixed(2)
      }
    },
    'budget.allWorkersSum'() {
      if (this.budgetInputsAllWorkersSum) {
        const clearValue = (this.budget.allWorkersSum + '').replaceAll('.', ',')
        this.allWorkersSum = clearValue || 0
        return
      }

      if (this.companyInfo) {
        // Исполнтелю на руки
        this.budget.oneWorkerSum = (this.budget.allWorkersSum / this.budget.workersAmount).toFixed(2)
        // Бюджет заказа
        const budget = this.budget.allWorkersSum / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)
        this.budget.budgetPlusTaxes = budget.toFixed(2)
      }
    },
    'budget.budgetPlusTaxes'() {
      // (Бюджет заказа, включая налог +% )=(Всем исполнителям “на руки”)*(1+0,06)*(1+0,0475)

      if (this.budgetInputsBudgetPlusTaxes) {
        const clearValue = (this.budget.budgetPlusTaxes + '').replaceAll('.', ',')
        this.budgetPlusTaxes = clearValue || 0
        return
      }

      if (this.companyInfo) {
        const clearBudget =
          this.budget.budgetPlusTaxes * (1 - this.companyInfo.unitpay_commission) * (1 - this.companyInfo['self-employed_tax'])

        // Всем исполнителям на руки
        const allWorkersSum = clearBudget || 0
        this.budget.allWorkersSum = allWorkersSum.toFixed(2)
        // Исполнтелю на руки
        this.budget.oneWorkerSum = (this.budget.allWorkersSum / this.budget.workersAmount).toFixed(2)
      }
    },
    'budget.workersAmount'() {
      this.budgetInputsBudgetPlusTaxes = true
      this.budgetInputsOneWorkerSum = true
      this.budgetInputsAllWorkersSum = true

      if (this.companyInfo && this.budget.oneWorkerSum && this.budget.workersAmount) {
        const budget = this.budget.budgetPlusTaxes * (1 - this.companyInfo.unitpay_commission) * (1 - this.companyInfo['self-employed_tax'])
        this.budget.allWorkersSum = budget.toFixed(2)
        this.budget.oneWorkerSum = (this.budget.allWorkersSum / this.budget.workersAmount).toFixed(2)
      }
    }
  }
})
export default class AddOrderExtended extends Vue {}
