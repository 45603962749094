
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'
import { API } from '@/utils/API'
import AuthLayout from '@/views/Registration/AuthLayout.vue'
import { EAccountTypes } from '@/types/api-values'
import { useAuthStore } from '@/stores/auth'

@Options({
  components: {
    UButton,
    AuthLayout
  },
  data() {
    return {
      id: '',
      companies: []
    }
  },
  methods: {
    choosenCompany(id: any) {
      this.companies.forEach((company: any) => {
        if (company.id === id) {
          sessionStorage.setItem('companyType', company.type)
        }
      })
    },
    async selectCompany() {
      const authStore = useAuthStore()
      if (this.id) {
        this.axios
          .post(
            API.AUTH_CHOOSECOMPANY,
            {
              company_id: this.id
            }
          )
          .then(async (response: any) => {
            const status = response?.status
            if (status === 201 || status === 200) {
              const newAccessToken = response?.data?.updated_access_token
              const jwtString = localStorage.getItem('JWT')
              const jwt = jwtString && JSON.parse(jwtString)
              const newJWT = {
                access_token: newAccessToken,
                refresh_token: jwt?.refresh_token
              }

              if (newJWT && newJWT.access_token) {
                authStore.saveJWT(newJWT)
              }

              const payload = authStore.payload
              const type = payload?.user_type
              const verified = payload?.user_verified
              const companyVerified = payload?.company_is_verified
              // const companyId = payload.company_id
              // const edoEnabled = payload.company_staff_status_to_sign

              if (type === EAccountTypes.staff) {
                if (verified === false) {
                  this.$router.push({
                    name: 'staff.notready'
                  })
                  return
                }
                // redirect to edo app
                window.location.pathname = '/edo'
                return
              }

              if (type === EAccountTypes.customer && companyVerified !== true) {
                this.$router.push({
                  name: 'not_verified_company_page'
                })
                return
              }

              // type of account in not allowed to use app
              if (type !== EAccountTypes.customer && type !== EAccountTypes.staff) {
                authStore.logout()
                return
              }

              // Select successful
              this.$router.push('/')
            }
          })
          .catch(() => {})
      }
    }
  },
  created() {
    this.axios
      .get(API.AUTH_CHOOSECOMPANY)
      .then((response: any) => {
        const companies = response?.data
        if (companies && Array.isArray(companies)) {
          this.companies = companies
        } else {
          this.$router.push('/')
        }
      })
      .catch(() => {
        this.$router.push('/')
      })
  }
})
export default class SelectCompany extends Vue {}
