
import { defineComponent } from 'vue'
import DoctypeBtn from '@/components/Button/DoctypeBtn.vue'
import DocumentsFilter from '@/components/Filters/DocumentFilter.vue'
import * as Sentry from '@sentry/browser'
import Loader from '@/components/Loader/Loader.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import moment from 'moment'
import axios from 'axios'
import { API } from '@/utils/API'
import { formatDocType } from '@/utils/doc-types'
import { DOCS_STATUS } from '@/utils/consts'
import { checkFileSize, formatName, formatTime } from '@/utils/helpers'
import { EDocsStatus } from '@/types/api-values'
import { UInput, UButton, UTable, UModal, UMultiselect, UFileDrop, UUploadProgress } from 'unit-uikit'
import { mapStores } from 'pinia'
import { useUiStore } from '@/stores/ui'

import jsonToFormData from '@/utils/jsonToForm'
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

function formatDateForQueryString(date: string): string {
  const [day, month, year] = date.split('.')
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export default defineComponent({
  components: {
    UModal,
    UTable,
    UInput,
    UButton,
    UMultiselect,
    UFileDrop,
    UUploadProgress,
    DoctypeBtn,
    EmptyPagePlaceholder,
    DocumentsFilter,
    Loader
  },
  data() {
    return {
      loading: true,
      loadWorkersList: false,
      v$: useValidate(),
      successCreated: false,
      failedcreate: false,
      showPopupSignUploadDocs: false,
      showPopup: false,
      filterData: {} as any,
      // Value 10000 is maximum and means - show all
      searchString: '',
      workerSecondName: '',
      documents: [] as any,
      filterDates: {
        start_date: null,
        finish_date: null
      },
      selectedDocType: [],
      selectedDocStatus: [],
      workersList: [] as any,
      selectedWorker: null as any,
      successModalShow: false,
      file: null as any,
      fileName: '',
      docName: '',
      uploadName: '',
      fileNameError: false,
      fileReadError: null as any,
      creatingInProcess: false,
      maxImgFileSize: 32,
      fileSizeError: false,
      scrollData: {
        count: 0,
        size: 20,
        page: 1
      }
    }
  },
  created() {
    this.loading = true
    this.getAllDocs()

    this.loadWorkersList = true
    axios
      .get(API.ORDER_PREINVITED_WORKERS + '?count=1000&complete_data=true&personaldata__is_self_employed=true&verified=true')
      .then((res: any) => {
        const documents = res.data.results
        this.workersList.push(...documents)
        this.loading = false
        this.loadWorkersList = false
      })
      .catch((err: any) => {
        console.error(err)
        this.loading = false
        this.loadWorkersList = false
      })
  },
  methods: {
    getAllDocs() {
      axios
        .get(API.GET_ALL_DOCS + `?page_size=${this.scrollData.size}&page=${this.scrollData.page}`)
        .then((res: any) => {
          this.scrollData.count = res.data?.count || 0
          const documents = res.data.results
          this.documents.push(...documents)
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          console.error(e)
          Sentry.captureException(e)
        })
    },
    formatTime,
    formatName,
    checkFileSize,
    addFile(file: File) {
      this.fileReadError = false
      this.fileNameError = false
      this.fileSizeError = false

      this.fileName = file.name
      this.file = file

      this.fileNameError = !file.type.includes('pdf')

      const error = checkFileSize(file, this.maxImgFileSize)

      if (error) {
        this.fileSizeError = true
        return
      }

      if (this.fileNameError) {
        return
      }

      const reader = new FileReader()
      reader.onload = (e: Event) => {
        const _file = (e.target as FileReader)?.result
        const regex = new RegExp('%PDF-1.[0-7]')
        let data = (_file as string).substring(0, 8)
        if (!data.match(regex)) {
          this.fileReadError = true
          return
        }
        if (!((_file as string).lastIndexOf('%%EOF') > -1)) {
          this.fileReadError = true
          return
        }
      }
      try {
        reader.readAsText(file)
      } catch (error) {
        console.error('read file error', error)
        this.fileReadError = true
        return
      }
    },
    openEditMenu(id: string | number) {
      this.documents = this.documents.map((item: any) => {
        const itemId = item.id
        if (id + '' === itemId + '') {
          item.showEditMenu = !item.showEditMenu
        } else {
          item.showEditMenu = false
        }
        return item
      })
    },
    closeEditMenu() {
      this.documents = this.documents.map((item: any) => {
        item.showEditMenu = false
        return item
      })
    },
    clearUploadDocForm(isDocUploaded: boolean) {
      this.selectedWorker = null
      this.fileName = ''
      this.docName = ''
      this.showPopupSignUploadDocs = false
      this.successModalShow = false

      if (isDocUploaded) {
        window.location.reload()
      }
    },
    signDoc() {
      this.showPopupSignUploadDocs = false
      this.loading = true

      const formData = jsonToFormData({
        file: this.file,
        file_name: this.docName,
        worker: this.selectedWorker.personaldata.user
      })

      axios
        .post(API.SIGN_DOCS, formData)
        .then(() => {
          this.successModalShow = true
          this.loading = false
        })
        .catch((err: any) => {
          console.error(err)
          this.successModalShow = false
          this.loading = false
        })
    },
    revokeDoc(id: string | number) {
      this.loading = true
      axios
        .post(API.REVOKE_DOC(id), { status: 'revoked' })
        .then(() => {
          this.loading = false
          window.location.reload()
        })
        .catch((err: any) => {
          console.error(err)
          this.loading = false
          window.location.reload()
        })
    },
    findDocumentsByFilter() {
      this.documents = []
      this.scrollData.count = 1
      this.scrollData.page = 0
      this.loadDocuments()
    },
    async loadDocuments() {
      if (this.documents.length < this.scrollData.count) {
        this.scrollData.page++
        let queryString = `?page_size=${this.scrollData.size}&page=${this.scrollData.page}`

        for (const key in this.filterData) {
          let additionalQuery = `&${key}=${this.filterData[key]}`

          if (key === 'start_date') {
            const formattedDate = moment(this.filterData[key]).format('DD.MM.YYYY')
            additionalQuery = `&start_date=${formatDateForQueryString(formattedDate)}T00:00:00`
          }

          if (key === 'finish_date') {
            const formattedDate = moment(this.filterData[key]).format('DD.MM.YYYY')
            additionalQuery = `&end_date=${formatDateForQueryString(formattedDate)}T23:59:59.99`
          }

          if (key === 'worker_phone') {
            additionalQuery = `&worker_phone=${this.filterData[key].replace('+', '').replaceAll('-', '')}`
          }

          if (key === 'selectedDocType') {
            const categories = this.filterData.selectedDocType
              .map((type: any) => {
                return type.value
              })
              .join(',')
            additionalQuery = `&category=${categories}`
          }

          if (key === 'selectedDocStatus') {
            const categories = this.filterData.selectedDocStatus
              .map((type: any) => {
                return type.value
              })
              .join(',')
            additionalQuery = `&status=${categories}`
          }

          if (key === 'worker_first_name') {
            const surname = this.filterData[key].replaceAll(' ', '%20')
            additionalQuery = `&worker_first_name=${surname}`
          }

          if (key === 'worker_last_name') {
            const surname = this.filterData[key].replaceAll(' ', '%20')
            additionalQuery = `&worker_last_name=${surname}`
          }

          if (!additionalQuery.endsWith('=')) {
            queryString += additionalQuery
          }
        }

        const response = await axios.get(API.GET_ALL_DOCS + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.scrollData.count = data.count || 0
          const documents = data.results
          this.documents.push(...documents)
          this.loading = false
        }
      }
    },
    createUploads() {
      this.creatingInProcess = true
      let queryString = 'page_size=10000'

      let periodBegin = null
      let periodEnd = null
      for (const key in this.filterData) {
        let additionalQuery = `&${key}=${encodeURIComponent(this.filterData[key])}`

        if (key === 'start_date') {
          const formattedDate = moment(this.filterData[key]).format('DD.MM.YYYY')
          periodBegin = formatDateForQueryString(formattedDate)
          additionalQuery = `&start_date=${periodBegin}T00:00:00`
        }

        if (key === 'finish_date') {
          const formattedDate = moment(this.filterData[key]).format('DD.MM.YYYY')
          periodEnd = formatDateForQueryString(formattedDate)
          additionalQuery = `&end_date=${periodEnd}T23:59:59.99`
        }

        if (key === 'selectedDocType') {
          const categories = this.filterData.selectedDocType
            .map((type: any) => {
              return type.value
            })
            .join(',')
          additionalQuery = `&category=${categories}`
        }

        if (key === 'selectedDocStatus') {
          const categories = this.filterData.selectedDocStatus
            .map((type: any) => {
              return type.value
            })
            .join(',')
          additionalQuery = `&status=${categories}`
        }

        if (key === 'worker_phone') {
          additionalQuery = `&worker_phone=${this.filterData[key].replace('+', '').replaceAll('-', '')}`
        }

        if (key === 'worker_first_name') {
          const surname = this.filterData[key].replaceAll(' ', '%20')
          additionalQuery = `&worker_first_name=${surname}`
        }

        if (key === 'worker_last_name') {
          const surname = this.filterData[key].replaceAll(' ', '%20')
          additionalQuery = `&worker_last_name=${surname}`
        }
        if (!additionalQuery.endsWith('=')) queryString += additionalQuery
      }

      axios
        .post(API.SET_DOCS_EXPORT + '?' + queryString, {
          query_link: API.GET_ALL_DOCS + '?' + queryString,
          name: this.uploadName,
          document_amount: this.scrollData.count,
          period_begin: periodBegin,
          period_end: periodEnd,
          add_certificates: true,
          add_agreements: true,
          category: [],
          all: false,
          has_procuration: false
        })
        .then(() => {
          this.showPopup = false
          this.successCreated = true
          this.creatingInProcess = false
        })
        .catch((e: any) => {
          Sentry.captureException(e)
          this.failedcreate = true
          this.showPopup = false
          this.creatingInProcess = false
        })
    },
    uploadDocs() {
      const checkFields = ['start_date', 'finish_date']

      let isInvalid = false

      checkFields.forEach((field) => {
        this.v$.filterDates[field].$touch()
        isInvalid = isInvalid || this.v$.filterDates[field].$invalid
      })

      if (isInvalid) {
        return
      }

      // Create name for uploading docs
      this.uploadName = `Выгрузка_${moment(new Date()).format('DD.MM.YY_HH:mm:ss')}`
      // end

      this.showPopup = true
    },
    fetchFilter(data: any) {
      this.filterData = data
    },
    clearFilters() {
      this.documents = []
      this.scrollData.count = 1
      this.scrollData.page = 0
      this.filterData = {}
      this.loadDocuments()
    },
    formatDate(date: Date | null): string {
      return date ? moment(date).format('DD.MM.YY') : ''
    },
    formatDocType,
    getTitleForSuborder(suborder: any) {
      if (!suborder || !suborder.name || suborder.name.length === 0) {
        return suborder?.initial_name || ''
      }
      return suborder.name
    },
    getDocClass(status: EDocsStatus): string {
      switch (status) {
        case EDocsStatus.signed:
          return 'text-success'
        case EDocsStatus.terminated:
        case EDocsStatus.declined_by_self_employee:
        case EDocsStatus.rejected:
        case EDocsStatus.revoked:
          return 'text-error'
        case EDocsStatus.finished:
          return 'text-gray-500'
        case EDocsStatus.not_signed:
        case EDocsStatus.active:
        case EDocsStatus.exists:
          return ''
        default:
          return ''
      }
    },
    formatDocStatus(status: string | undefined): string {
      return status ? DOCS_STATUS[status.toLowerCase() as EDocsStatus] || '' : ''
    }
  },
  watch: {
    'uiStore.showSignUploadDocsPopup'(val) {
      this.showPopupSignUploadDocs = val
    },
    showPopupSignUploadDocs(val) {
      this.uiStore.showSignUploadDocsPopup = val
    },
    'uiStore.showUploadsPopup'(val) {
      this.showPopup = val
      this.uploadName = `Выгрузка_${moment(new Date()).format('DD.MM.YY_HH:mm:ss')}`
    },
    showPopup(val) {
      this.uiStore.showUploadsPopup = val
      this.uploadName = `Выгрузка_${moment(new Date()).format('DD.MM.YY_HH:mm:ss')}`
    }
  },
  validations() {
    return {
      filterDates: {
        start_date: { required },
        finish_date: { required }
      }
    }
  },
  computed: {
    ...mapStores(useUiStore),
    disableSign() {
      return (
        !this.docName.length ||
        this.docName.length > 100 ||
        !this.selectedWorker ||
        !this.fileName ||
        this.fileNameError ||
        this.fileSizeError ||
        this.fileReadError
      )
    }
  }
})
