import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "whitespace-nowrap" }
const _hoisted_3 = { class: "heading-gray-3" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.agreement)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, "От " + _toDisplayString(_ctx.formatDate(_ctx.agreement.created)) + " до " + _toDisplayString(_ctx.formatDate(_ctx.agreement.exp_date)), 1),
        _createElementVNode("p", _hoisted_3, "№ " + _toDisplayString(_ctx.agreement.id), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, "Отсутствует"))
}