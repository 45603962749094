
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'UploadDocs',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  data() {
    return {}
  },
  props: {
    id: { type: [String, Number] },
    label: { type: String }
  },
  methods: {
    updateInput(event: any) {
      const files = [...this.$attrs.modelValue, ...event.target.files]
      this.$emit('update:modelValue', files)
    }
  }
})
export default class UploadDocs extends Vue {}
