
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  props: {
    show: Boolean,
    errorMessage: String,
    errorAction: Function
  },
  components: {
    UButton
  }
})
export default class ErrorModal extends Vue {}
