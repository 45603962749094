
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { API } from '@/utils/API'
import AuthLayout from '@/views/Registration/AuthLayout.vue'
import { UInput, UButton, UICheckbox } from 'unit-uikit'
import { useAuthStore } from '@/stores/auth'
import { useStaffStore } from '@/stores/staff'

import useValidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { EAccountTypes } from '@/types/api-values'

const POLITICS_ERROR = 'Пожалуйста, принимите правила электронной площадки'

@Options({
  components: {
    UButton,
    UInput,
    UICheckbox,
    AuthLayout
  },
  data() {
    const name = this.$route?.redirectedFrom?.name
    return {
      staffStore: useStaffStore(),
      authStore: useAuthStore(),
      v$: useValidate(),
      loginView: true,
      showSMSCode: false,
      showRegistrationSMSCode: false,
      timer: 60,
      timeout: null,
      user: {
        phone: '',
        name: '',
        code: '',
        account_type: name === 'referral' ? EAccountTypes.staff : EAccountTypes.customer
      },
      codeError: false,
      loginError: '',
      personalPolitics: false,
      EAccountTypes
    }
  },
  methods: {
    closeSMS() {
      this.loginView = true
      this.showRegistrationSMSCode = false
      this.showSMSCode = false
    },
    sendSMS() {
      this.authStore.login(this.user).then(
        () => {
          // Move to page for input sms code
          this.startTimer()
        },
        (error: any) => {
          console.error(error)
        }
      )
    },
    toSmsReg() {
      if (!this.personalPolitics) {
        this.loginError = POLITICS_ERROR
        return
      }
      this.loginError = ''
      this.v$.user.phone.$touch()
      if (this.v$.user.phone.$invalid) {
        return
      }

      const user = this.user
      const phone = user?.phone?.replaceAll('-', '')
      const companyId = this.staffStore.getReferral()

      switch (this.user?.account_type) {
        case EAccountTypes.staff:
          this.user.name = this.user.phone
          this.user.name = this.user.name.slice(0, 10)

          this.authStore
            .register({
              name: user.name,
              phone: user.phone,
              account_type: user.account_type,
              company_id: companyId,
              is_invite: true
            })
            .then(
              (data: any) => {
                if (data.position && data.company_name) {
                  this.staffStore.updateCompany({
                    position: data.position,
                    name: data.company_name,
                    username: phone
                  })
                  this.showSMSCode = true
                  this.showRegistrationSMSCode = true
                  this.startTimer()
                } else {
                  this.loginError = 'Для регистрации получите приглашение от компании'
                }
              },
              (error: any) => {
                const errorText = error.response?.data?.non_field_errors
                if (errorText) {
                  this.loginError = errorText
                } else {
                  this.loginError = 'Для регистрации получите приглашение от компании'
                }
              }
            )
          break

        case EAccountTypes.customer:
          this.axios
            .post(API.SIGNUP, { phone: phone, account_type: EAccountTypes.customer })
            .then(() => {
              this.showSMSCode = true
              this.showRegistrationSMSCode = true
              this.startTimer()
            })
            .catch((err: any) => {
              const errorText = err.response?.data?.non_field_errors || err.response?.data?.response?.non_field_errors
              if (errorText) {
                this.loginError = errorText
              }
            })
          break

        default:
          this.showSMSCode = true
          this.showRegistrationSMSCode = true
          break
      }
    },
    login() {
      if (!this.personalPolitics) {
        this.loginError = POLITICS_ERROR
        return
      }
      this.loginError = ''
      this.v$.user.phone.$touch()
      if (this.v$.user.phone.$invalid) {
        return
      }

      const user = this.user

      this.authStore.login(user).then(
        () => {
          // Move to page for input sms code
          this.showSMSCode = true
          this.startTimer()
        },
        (error: any) => {
          const errorText = error.response?.data?.non_field_errors
          if (errorText) {
            this.loginError = errorText
          }
        }
      )
    },
    regConfirm() {
      const user = this.user
      const phone = user?.phone?.replaceAll('-', '')

      switch (this.user?.account_type) {
        case EAccountTypes.staff:
          this.axios
            .post(API.SIGNUP_CHECK_CODE, {
              phone,
              code: user.code,
              account_type: EAccountTypes.staff
            })
            .then((response: any) => {
              if (response.data.is_valid) {
                this.$router.push('/registration')
                this.$router.push({
                  name: 'staff.inn'
                })
              }
            })
            .catch((error: any) => {
              this.codeError = true
              console.error(error)
            })
          break
        case EAccountTypes.customer:
          this.axios
            .post(API.SIGNUP_CHECK_CODE, {
              phone,
              code: user.code,
              account_type: EAccountTypes.customer
            })
            .then((response: any) => {
              if (response.data.is_valid) {
                localStorage.setItem('userPhone', user.phone)
                sessionStorage.setItem('regSmsCode', user.code)
                this.$router.push('/registration')
              }
            })
            .catch((error: any) => {
              this.codeError = true
              console.error(error)
            })

          break

        default:
          break
      }
    },
    loginConfirm() {
      const user = this.user

      this.authStore.loginConfirm(user).then(
        () => {
          this.$router.push({
            name: 'SelectCompany'
          })
        },
        (error: any) => {
          this.codeError = true
          console.error(error)
        }
      )
    },
    startTimer() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timer = 60
      this.timeout = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearTimeout(this.timeout)
          this.timeout = null
        }
      }, 1000)
    },
    setActiveTab(type: EAccountTypes.customer) {
      this.user.account_type = type
    },
    isActiveTab(type: EAccountTypes.customer) {
      return type === this.user?.account_type ? '-active' : ''
    },
    handleKeyDown(evt: any) {
      if (evt.keyCode === 8 && (evt.target.selectionStart === 2 || evt.target.selectionStart === 3)) {
        evt.preventDefault()
      }
    },
    handlePaste(event: any) {
      event.preventDefault()
      let _pastePhone = (event.clipboardData || (window as any).clipboardData).getData('text').replace(/[^0-9]/g, '')

      if (_pastePhone.length >= 10 && _pastePhone.startsWith('8')) {
        _pastePhone = '7' + _pastePhone.slice(1)
      }

      this.user.phone = _pastePhone
    }
  },
  computed: {
    formatPhone() {
      if (this.user.phone.length === 0) {
        this.user.phone = '+7'
      }

      return this.user.phone
    },
    phonePlaceholder() {
      if (this.loginView) {
        return 'Введите номер'
      }
      return 'Введите номер телефона ответственного лица'
    },
    mainTitle() {
      return this.loginView ? 'Войти' : 'Регистрация'
    },
    lkWorkerUrl() {
      return process.env.VUE_APP_CLIENT_URL
    }
  },
  validations() {
    return {
      user: {
        phone: { required, minLength: minLength(16) }
      }
    }
  },
  watch: {
    personalPolitics(val) {
      if (val && this.loginError === POLITICS_ERROR) {
        this.loginError = ''
      }
    },
    'user.code'() {
      this.codeError = false
    }
  }
})
export default class Login extends Vue {
  @Prop({ type: Boolean, default: false }) readonly hideActions!: boolean
}
