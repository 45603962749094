
import { PropType, defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import { UTooltip, UTable } from 'unit-uikit'
import { formatPrice } from '@/utils/helpers'
import { APIAddWorkersBudget, APIAddWorkersOrder } from '@/interfaces/main'
import moment from 'moment'
import WorkerTableItem from './OrderComponents/WorkerTableItem.vue'
import ChangeSuborderModal from '@/components/Modals/ChangeSuborderModal.vue'
import WorkerLimitsTableItem from './OrderComponents/WorkerLimitsTableItem.vue'
import WorkerPriceTableItem from './OrderComponents/WorkerPriceTableItem.vue'

export default defineComponent({
  name: 'AddOrder2',
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    v$: Object,
    budget: {
      type: Object as PropType<APIAddWorkersBudget>,
      required: true
    },
    specialErrors: {
      type: Object as any
    }
  },
  data() {
    return {
      unitTypesList: [],
      workersAmount: null as any,
      workersIDs: [] as any,
      editableSuborder: null as any
    }
  },
  components: {
    UTooltip,
    WorkerTableItem,
    ChangeSuborderModal,
    UTable,
    WorkerLimitsTableItem,
    WorkerPriceTableItem
  },
  methods: {
    formatDate(date: any) {
      return moment(date).format('DD.MM.yy')
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    getUnitType(type: number) {
      const result = this.unitTypesList
        .filter((item: any) => {
          return item.id + '' === type + ''
        })
        .map((item: any) => {
          return item.name
        })
      return (result && result[0]) || ''
    },
    changeSuborder(data: any) {
      this.order.suborder = this.order.suborder.map((order: any) => {
        if (order.id + '' === this.editableSuborder.id + '') {
          order.quantity = data.quantity
          order.price = data.price
          order.name = data.name
          order.description = data.description
          order.unit_types = data.unit_type
          order.start_date = this.formatDate(data.start_date)
          order.finish_date = this.formatDate(data.finish_date)
        }
        return order
      })
      axios
        .post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, {
          workers: this.workersIDs
        })
        .then((res: any) => {
          this.order.suborder.forEach((worker: any) => {
            if (worker?.start_date) {
              const newFormattedDate = new Date(
                `${worker.start_date.slice(6, 10)}.${worker.start_date.slice(3, 5)}.${worker.start_date.slice(0, 2)}`
              )
              res?.data.forEach((item: any) => {
                const oldFormattedDate = new Date(item.start_date)
                // newFormattedDate < oldFormattedDate ? (worker.new_agremeent_warning = true) : (worker.new_agremeent_warning = false)
                if (newFormattedDate < oldFormattedDate) {
                  if (worker.id === item.worker_id) {
                    worker.current_agremeent_exist = Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = Object.values(item).includes(worker.id)
                  }
                }
                if (newFormattedDate.toDateString() === oldFormattedDate.toDateString()) {
                  if (worker.id === item.worker_id) {
                    worker.current_agremeent_exist = !Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = !Object.values(item).includes(worker.id)
                  }
                }
              })
            }
          })
        })
        .catch((error: any) => {
          console.error(error)
        })
      this.editableSuborder = null
    }
  },
  created() {
    setTimeout(() => {
      this.workersAmount = this.budget.workersAmount

      this.order.suborder.forEach((worker: any) => {
        this.workersIDs.push(worker.id)
      })

      axios
        .post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, {
          workers: this.workersIDs
        })
        .then((res: any) => {
          this.order.suborder.forEach((worker: any) => {
            if (worker?.start_date) {
              const newFormattedDate = new Date(
                `${worker.start_date.slice(6, 10)}.${worker.start_date.slice(3, 5)}.${worker.start_date.slice(0, 2)}`
              )
              res?.data.forEach((item: any) => {
                const oldFormattedDate = new Date(item.start_date)
                if (newFormattedDate < oldFormattedDate) {
                  if (worker.id === item.worker_id) {
                    worker.current_start_date = this.formatDate(item.start_date)
                    worker.current_end_date = this.formatDate(item.end_date)
                    worker.current_agremeent_exist = Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = Object.values(item).includes(worker.id)
                  }
                }
                if (newFormattedDate.toDateString() === oldFormattedDate.toDateString()) {
                  if (worker.id === item.worker_id) {
                    worker.current_start_date = this.formatDate(item.start_date)
                    worker.current_end_date = this.formatDate(item.end_date)
                    worker.current_agremeent_exist = !Object.values(item).includes(worker.id)
                    worker.new_agremeent_warning = !Object.values(item).includes(worker.id)
                  }
                }
              })
            }
          })
        })
        .catch((error: any) => {
          console.error(error)
        })

      axios.get(API.GET_UNIT_TYPES).then((res: any) => {
        this.unitTypesList = res.data
      })

      const length: any = this.budget.workersAmount

      let quantity = this.order?.quantity
      const budget = this.budget.workerWithTax ? this.budget.workerWithTax : 0
      const taskName = this.order?.name
      const unitTypes = this.order?.unit_type
      const startDate = this.formatDate(this.order?.start_date)
      const finishDate = this.formatDate(this.order?.finish_date)
      const description = this.order?.description || this.order?.name

      if (length) {
        const amount: any = quantity
        if (amount) {
          const value = amount / length
          quantity = value.toFixed(2)
        }
      }

      this.order.suborder = this.order.suborder.map((item: any) => {
        item.quantity = item.quantity || quantity
        item.price = item.price || budget
        item.name = item.name || taskName
        item.unit_types = item.unit_types || unitTypes
        item.start_date = item.start_date || startDate
        item.finish_date = item.finish_date || finishDate
        item.description = item.description || description

        return item
      })
    }, 200)
  },
  computed: {
    getTotalBudget() {
      const budget: any = this.budget?.workerWithTax ? this.budget?.workerWithTax : 0
      const limit = budget * this.workersAmount
      return limit
    },
    limitPriceAll() {
      const limit = this.getTotalBudget
      const usedValues = this.order?.suborder.reduce((prev: any, next: any) => {
        const price = (next.price + '').replaceAll(',', '.').replaceAll(' ', '')
        return prev + +price
      }, 0)

      if (limit - usedValues < 0) {
        if (this.specialErrors) {
          this.specialErrors.limitPrice = true
          return true
        }
      }

      if (this.specialErrors) {
        this.specialErrors.limitPrice = false
        return false
      }
    }
  }
})
