
import { PropType, defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import WorkerTableItem from './OrderComponents/WorkerTableItem.vue'
import WorkerWarningLimits from '@/views/Orders/OrderComponents/WorkerWarningLimits.vue'
import { APIAddWorkersBudget, APIAddWorkersOrder } from '@/interfaces/main'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import onScroll from '@/utils/infiniteScroll'
import debounce from '@/utils/debounce'
import { UInput } from 'unit-uikit'

export default defineComponent({
  name: 'AddOrder2',
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    v$: Object,
    budget: {
      type: Object as PropType<APIAddWorkersBudget>,
      required: true
    },
    specialErrors: Object
  },
  data() {
    return {
      loading: true,
      workers: [] as any,
      checkedWorkers: [] as any[],
      checkedWorkersData: [] as any[],
      searchFirstName: '',
      searchLastName: '',
      isAllWorkers: true,
      ready: false,
      workersAmount: null as any,
      startDate: '',
      endDate: '',
      suborders: {},
      infiniteLoader: false,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 10,
        page: 1
      },
      debounceLoadWorkersWithParams: null as any
    }
  },
  components: {
    WorkerTableItem,
    UInput,
    Loader,
    WorkerWarningLimits
  },
  methods: {
    async getWorkersInfo(workersList: any) {
      this.loading = true
      const asyncRes = await Promise.all(
        workersList.map(async (id: any) => {
          const response = await axios.get(API.PROFILE_WORKER(id))

          const worker = response?.data
          if (worker) {
            worker.id = id
          }
          return worker
        })
      )
      this.loading = false
      return asyncRes
    },
    loadWorkersWithParams() {
      this.scrollData.page = 0
      this.workers = []
      this.loadWorkers()
    },
    loadWorkers() {
      if (!this.infiniteLoader) {
        if (this.workers.length < this.scrollData.count) {
          this.infiniteLoader = true
          this.scrollData.page++

          this.startDate = moment(new Date(this.order.start_date)).format('YYYY-MM-DD')
          this.endDate = moment(new Date(this.order.finish_date)).format('YYYY-MM-DD')

          let queryString = `?page=${this.scrollData.page}&count=${this.scrollData.size}&complete_data=true&personaldata__is_self_employed=true`

          if (this.searchFirstName) {
            queryString += `&first_name=${this.searchFirstName}`

            if (this.workers.length && !(this.workers.length < this.scrollData.requestCount)) {
              this.infiniteLoader = false
              return
            }
          }
          if (this.searchLastName) {
            queryString += `&last_name=${this.searchLastName}`

            if (this.workers.length && !(this.workers.length < this.scrollData.requestCount)) {
              this.infiniteLoader = false
              return
            }
          }
          axios
            .get(API.ORDER_PREINVITED_WORKERS + queryString + '&verified=true')
            .then((response) => {
              this.scrollData.requestCount = response.data.count
              const workers = response.data.results.map((item: any) => {
                item.id = item.personaldata?.user
                return item
              })
              this.workers.push(...workers).sort((a: any, b: any) => (a.last_name > b.last_name ? 1 : -1))

              this.infiniteLoader = false
            })
            .catch(() => {
              this.infiniteLoader = false
            })
        }
      }
    },
    onScroll() {
      if (this.workers.length < this.scrollData.count) {
        onScroll(this.$refs.orderLIstWrapper, this.$refs.orderLIstContent, this.loadWorkers)
      }
    },
    updateOrder() {
      this.order.suborder = this.checkedWorkersData.filter((w: any) => {
        return this.checkedWorkers.includes(w.id)
      })
    },
    addWoker(id: any) {
      if (!this.checkedWorkers.includes(id) && this.checkedWorkers.length < this.workersAmount) {
        this.checkedWorkers.push(id)
      }
    },
    removeWoker(id: any) {
      this.checkedWorkers = this.checkedWorkers.filter((item: any) => item !== id)
    },
    removeWorkersOutOfLimit() {
      this.checkedWorkersData.forEach((item: any) => {
        if (this.disableAddWorker(item)) {
          this.removeWoker(item.id)
        }
      })
    },
    disableAddWorker(worker: any) {
      if (this.order.withholding_tax) {
        return worker.month_limit <= 0
      }
      return worker.month_limit_without_tax <= 0
    }
  },
  created() {
    this.workersAmount = this.budget.workersAmount

    this.startDate = moment(new Date(this.order?.start_date)).format('YYYY-MM-DD')
    this.endDate = moment(new Date(this.order?.finish_date)).format('YYYY-MM-DD')

    axios
      .get(
        API.ORDER_PREINVITED_WORKERS + `?count=${this.scrollData.size}&complete_data=true&verified=true&personaldata__is_self_employed=true`
      )
      .then((response) => {
        this.scrollData.count = response.data.count
        this.scrollData.requestCount = response.data.count

        this.workers = response.data.results
          .map((item: any) => {
            item.id = item.personaldata?.user
            if (this.order?.suborder?.length) {
              this.order.suborder = this.order?.suborder.map((suborder: any) => {
                if (suborder.id + '' === item.id + '') {
                  suborder.personaldata = item.personaldata
                  suborder.first_name = item.first_name
                  suborder.last_name = item.last_name
                  suborder.middle_name = item.middle_name
                }
                return suborder
              })
            }
            return item
          })
          .sort((a: any, b: any) => (a.last_name > b.last_name ? 1 : -1))

        this.loading = false
        this.ready = true
        this.infiniteLoader = false
      })
      .catch((e) => {
        console.error(e)
        this.loading = false
        this.ready = true
        this.infiniteLoader = false
      })
  },
  beforeUpdate() {
    this.removeWorkersOutOfLimit()
  },
  watch: {
    ready() {
      if (this.ready && this.order?.suborder?.length) {
        this.checkedWorkers = this.order.suborder.map((order: any) => {
          return order.id
        })
      }
    },
    searchFirstName() {
      if (this.debounceLoadWorkersWithParams) {
        this.debounceLoadWorkersWithParams()
      } else {
        this.debounceLoadWorkersWithParams = debounce(() => {
          this.loadWorkersWithParams()
        })
        this.debounceLoadWorkersWithParams()
      }
    },
    searchLastName() {
      if (this.debounceLoadWorkersWithParams) {
        this.debounceLoadWorkersWithParams()
      } else {
        this.debounceLoadWorkersWithParams = debounce(() => {
          this.loadWorkersWithParams()
        })
        this.debounceLoadWorkersWithParams()
      }
    }
  },
  computed: {
    async selectedWorkersData() {
      let idsWorkersActiveAgreement: any = []

      const aggrementsData = await axios
        .post(API.DOC_NOTIFICATIONS_AGREEMENT_EXIST, {
          workers: this.checkedWorkers
        })

      if (aggrementsData?.data) {
        aggrementsData.data.forEach((worker: any) => {
          idsWorkersActiveAgreement.push(worker.worker_id)
        })
      }

      const workers = await this.getWorkersInfo(this.checkedWorkers)

      workers.map((item: any) => {
        if (idsWorkersActiveAgreement.includes(item.id)) {
          item.agreement = [true]
        }
      })

      this.checkedWorkersData = workers
      this.updateOrder()
      return workers
    }
  }
})
