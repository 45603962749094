
import { Options, Vue } from 'vue-class-component'
import { useUiStore } from '@/stores/ui'
import moment from 'moment'
import { API } from '@/utils/API'
import axios from 'axios'
import Loader from '@/components/Loader/Loader.vue'
import DoctypeBtn from '@/components/Button/DoctypeBtn.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import { UTable, UInput, UISelect, UButton, UMultiselect, UICheckbox } from 'unit-uikit'
import OmvdActions from './OmvdActions.vue'
import { formatName } from '@/utils/helpers'
import debounce from '@/utils/debounce'
import { SERVER_COUNTRIES } from '@/utils/consts'
import COUNTRIES from '@/utils/Countries'
import {
  EOmvdNotificationStatus,
  getNotificationsIds,
  notificationsStatusOptions,
  switchNotificationStatus,
  normalizeNotifications
} from './omvd-helpers'

const ACCEPTED_STATUSES =
  'app_7=exists,signed,not_signed,active,finished,terminated,converting_error,null&status=exists,signed,not_signed,active,finished,terminated,converting_error,filed,rejected'

function tickRemoveTimer(notification: any) {
  notification.removeTimerId = setTimeout(() => {
    notification.removeTimer--
    if (notification.removeTimer > 0) {
      tickRemoveTimer(notification)
    } else {
      const status = EOmvdNotificationStatus.removed
      const id = notification.id
      axios
        .patch(API.REMOVE_DOCUMENT(id), {
          status
        })
        .then(() => {
          notification.isRemoved = true
        })
        .catch(() => {
          //
        })
    }
  }, 1000)
}

@Options({
  components: {
    UTable,
    DoctypeBtn,
    Loader,
    UICheckbox,
    UInput,
    UISelect,
    UButton,
    UMultiselect,
    EmptyPagePlaceholder,
    OmvdActions
  },
  emits: ['updateCounter'],
  data() {
    return {
      uiStore: useUiStore(),
      loading: false,
      notifications: [],
      objects: [],
      objectsOptions: [],
      allNotificationsStatus: null,
      mvdSearch: '',
      searchFirstName: '',
      searchLastName: '',
      notificationsOptions: [
        {
          value: 'signed,active',
          label: 'Подписан'
        },
        {
          value: 'not_signed',
          label: 'Не подписан'
        },
        {
          value: 'finished',
          label: 'Завершён'
        }
      ],
      notificationsStatusOptions,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      selectAll: false,
      checkedNotifications: [],
      citizenship: null,
      nationalities: [
        {
          id: '',
          name: 'Показать все'
        },
        {
          id: SERVER_COUNTRIES.RUSSIA,
          name: COUNTRIES[SERVER_COUNTRIES.RUSSIA]
        },
        {
          id: SERVER_COUNTRIES.BELARUS,
          name: COUNTRIES[SERVER_COUNTRIES.BELARUS]
        },
        {
          id: SERVER_COUNTRIES.ARMENIA,
          name: COUNTRIES[SERVER_COUNTRIES.ARMENIA]
        },
        {
          id: SERVER_COUNTRIES.KAZAKHSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KAZAKHSTAN]
        },
        {
          id: SERVER_COUNTRIES.KYRGYZSTAN,
          name: COUNTRIES[SERVER_COUNTRIES.KYRGYZSTAN]
        },
        {
          id: SERVER_COUNTRIES.UKRAINE,
          name: COUNTRIES[SERVER_COUNTRIES.UKRAINE]
        },
        {
          id: SERVER_COUNTRIES.DNR,
          name: COUNTRIES[SERVER_COUNTRIES.DNR]
        },
        {
          id: SERVER_COUNTRIES.LNR,
          name: COUNTRIES[SERVER_COUNTRIES.LNR]
        }
      ]
    }
  },
  methods: {
    getNotificationsIds,
    updateMultipleStatuses({ status }: any) {
      this.loading = true
      const ids: any = []
      this.selectedNotifications.forEach((n: any) => {
        if (n.status === 'not_signed') {
          return
        }
        if (n.start_work_notification) {
          ids.push(n.start_work_notification.id)
        }
        if (status === EOmvdNotificationStatus.rejected && n.end_work_notification) {
          ids.push(n.end_work_notification.id)
        }
      })
      switchNotificationStatus(status, ids)
        .then(() => {
          this.scrollData.count = -1
          this.loadDocsWithParams()
          this.$emit('updateCounter')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          //
        })
    },
    cancelRemoveNotification(notification: any) {
      if (notification.removeTimerId) {
        notification.removeAction = false
        clearTimeout(notification.removeTimerId)
      }
    },
    switchNotificationStatusToRemove(notification: any) {
      notification.removeAction = true
      notification.removeTimer = 10
      tickRemoveTimer(notification)
    },
    switchNotificationStatus(event: any, notification: any) {
      this.loading = true
      const ids = [notification.start_work_notification?.id]
      const status = event?.value
      if (status === EOmvdNotificationStatus.rejected) {
        ids.push(notification.end_work_notification?.id)
      }
      switchNotificationStatus(status, ids)
        .then(() => {
          this.scrollData.count = -1
          this.loadDocsWithParams()
          this.$emit('updateCounter')
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          //
        })
    },
    getDocLink(link: string) {
      if (link) {
        return link.replace(/^(http:\/\/)/, 'https://')
      }
      return ''
    },

    loadDocsWithParams() {
      this.scrollData.page = 0
      this.notifications = []
      this.loadDocs()
    },
    async loadDocs() {
      if (this.notifications.length < this.scrollData.count || this.scrollData.count === -1) {
        let queryParams = ''
        if (this.mvdSearch) {
          queryParams += `&omvd=${this.mvdSearch}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.searchFirstName) {
          queryParams += `&first_name=${this.searchFirstName}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.searchLastName) {
          queryParams += `&last_name=${this.searchLastName}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.objects.id) {
          queryParams += `&object=${this.objects.id}`
          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.allNotificationsStatus) {
          const statuses = this.allNotificationsStatus.map((s: any) => s.value).join(',')

          queryParams += `&status=${statuses}`

          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.citizenship) {
          queryParams += `&citizenship=${this.citizenship}`

          if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
            return
          }
        }

        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&count=${this.scrollData.size}&${ACCEPTED_STATUSES}` + queryParams
        this.uiStore.selectAllNotificationsQueryParams = `${ACCEPTED_STATUSES}` + queryParams

        const response = await axios.get(API.GET_DOCUMENTS_NOTIFICATIONS + queryString)

        const data = response.data
        if (data.results && data.count) {
          if (this.scrollData.count === -1) {
            this.scrollData.count = data?.count || 0
          }
          this.scrollData.requestCount = data?.count || 0
          const notifications = normalizeNotifications(data?.results || [])
          this.notifications.push(...notifications)
        }
      }
    },
    formatName,
    formatDate(date: any) {
      return moment(new Date(date)).format('DD.MM.YY')
    },
    getCitizenship(citizenship: any) {
      return COUNTRIES[citizenship]
    },
    getCertificateLink(link: string) {
      const URL_CLEAR = process.env.VUE_APP_URL
      return `${URL_CLEAR}${link}`
    },
    async getObjectsData() {
      const _objects = await axios.get(API.GET_OBJECT)
      this.objects = _objects.data
      this.objectsOptions = [
        {
          id: '',
          name: 'Показать все'
        },
        ..._objects.data
      ]
    },
    getNotificationsData() {
      this.loading = true
      const queryString = `?page=1&count=${this.scrollData.size}&${ACCEPTED_STATUSES}`

      axios
        .get(API.GET_DOCUMENTS_NOTIFICATIONS + queryString)
        .then((res) => {
          this.scrollData.count = res.data?.count || 0
          this.notifications = normalizeNotifications(res.data?.results || [])
          this.loading = false
        })
        .catch((e) => {
          console.error('error here', e)
          this.loading = false
        })
    },
    debounceWatcherCall() {
      if (this.debounceloadDocsWithParams) {
        this.debounceloadDocsWithParams()
      } else {
        this.debounceloadDocsWithParams = debounce(() => {
          this.loadDocsWithParams()
        })
        this.debounceloadDocsWithParams()
      }
    }
  },
  updated() {
    if (!this.searchFirstName && !this.searchLastName && this.$route.query.firstName && this.$route.query.lastName) {
      this.$router.replace({ query: null })
      this.getNotificationsData()
    }
  },
  created() {
    if (this.$route.query.firstName && this.$route.query.lastName) {
      this.searchFirstName = this.$route.query.firstName
      this.searchLastName = this.$route.query.lastName

      this.loading = true

      let queryParams = ''

      if (this.searchFirstName) {
        queryParams += `&first_name=${this.searchFirstName}`
        if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
          return
        }
      }

      if (this.searchLastName) {
        queryParams += `&last_name=${this.searchLastName}`
        if (this.notifications.length && !(this.notifications.length < this.scrollData.requestCount)) {
          return
        }
      }

      const queryString = `?page=1&count=${this.scrollData.size}&${ACCEPTED_STATUSES}` + queryParams

      axios
        .get(API.GET_DOCUMENTS_NOTIFICATIONS + queryString)
        .then((res) => {
          this.notifications = normalizeNotifications(res.data?.results || [])
          this.loading = false
        })
        .catch((e) => {
          console.error('error here', e)
          this.loading = false
        })
    } else {
      this.getNotificationsData()
    }
    this.getObjectsData()
    this.uiStore.checkedNotifications = []
    this.uiStore.selectAllNotifications = !!this.selectAll
    this.uiStore.selectAllNotificationsQueryParams = `${ACCEPTED_STATUSES}`
  },
  watch: {
    checkedNotifications() {
      this.uiStore.checkedNotifications = this.checkedNotifications
    },
    'objects.id'() {
      this.debounceWatcherCall()
    },
    mvdSearch() {
      this.debounceWatcherCall()
    },
    searchFirstName() {
      if (!this.$route.query.firstName && !this.$route.query.lastName) {
        this.debounceWatcherCall()
      }
    },
    searchLastName() {
      if (!this.$route.query.firstName && !this.$route.query.lastName) {
        this.debounceWatcherCall()
      }
    },
    citizenship() {
      this.debounceWatcherCall()
    },
    allNotificationsStatus() {
      this.debounceWatcherCall()
    },
    selectAll() {
      this.uiStore.selectAllNotifications = !!this.selectAll

      if (this.selectAll) {
        const ids = [] as any

        this.notifications.forEach((n: any) => {
          if (n.end_work_notification) {
            ids.push(this.getNotificationsIds(n, 1))
          }
        })

        this.checkedNotifications = ids
      } else {
        this.checkedNotifications = []
      }
    }
  },
  computed: {
    selectedNotifications() {
      return (
        this.notifications?.filter((n: any) => {
          const isSigned = n.status !== 'not_signed'
          return isSigned && (this.checkedNotifications?.includes(this.getNotificationsIds(n, 1)) || false)
        }) || []
      )
    }
  }
})
export default class OmvdNotifications extends Vue {}
