
import { Options, Vue } from 'vue-class-component'
import AuthLayout from '@/views/Registration/AuthLayout.vue'

@Options({
  components: {
    AuthLayout
  },
  data() {
    return {}
  }
})
export default class Login extends Vue {}
