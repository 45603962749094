
import { defineComponent } from 'vue'
import axios from 'axios'
import { formatDateSuborderModal, formatPrice } from '@/utils/helpers'
import { UTooltip, UModal, UButton, UISelect, UInput, UDateRange, UTextarea } from 'unit-uikit'
import d from '@/dictionary'
import { API } from '@/utils/API'

export default defineComponent({
  props: {
    show: Boolean,
    suborder: Object,
    tax: Boolean,
    limit: Number,
    limitTypes: Array,
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    UDateRange,
    UInput,
    UISelect,
    UTooltip,
    UButton,
    UModal,
    UTextarea
  },
  data() {
    return {
      quantityToChange: '',
      newSuborder: {} as any,
      suborderDates: {
        start_date: null,
        finish_date: null
      } as any,
      dateReady: false,
      unitTypesList: [],
      d
    }
  },
  created() {
    axios.get(API.GET_UNIT_TYPES).then((res: any) => {
      this.unitTypesList = res.data
    })

    const startDate = this.getStartDate(this.suborder)
    const finishDate = this.getFinishDate(this.suborder)
    if (startDate) {
      this.suborderDates.start_date = startDate
    }
    if (finishDate) {
      this.suborderDates.finish_date = finishDate
    }
    this.dateReady = true

    this.newSuborder.description = this.suborder?.description || ''
    this.newSuborder.name = this.suborder?.name || ''
    this.newSuborder.quantity = this.getQuantity(this.suborder)

    if (this.newSuborder.quantity.includes('.')) {
      this.quantityToChange = this.newSuborder.quantity.replace('.', ',')
    }

    this.newSuborder.price = this.getPrice(this.suborder)
    this.newSuborder.unit_type = this.getUnitType(this.suborder)
  },
  methods: {
    getStartDate(task: any) {
      return formatDateSuborderModal(task?.start_date || task?.initial_start_date)
    },
    getFinishDate(task: any) {
      return formatDateSuborderModal(task?.finish_date || task?.initial_finish_date)
    },
    getQuantity(task: any) {
      if (task.quantity && +task.quantity > 0) {
        return task.quantity
      }
      return task.initial_quantity
    },
    getUnitType(task: any) {
      return task.unit_type || task.unit_types?.id || task.unit_types
    },
    getPrice(task: any) {
      const price = (task.price + '').replaceAll(',', '.').replaceAll(' ', '')
      if (price && +price > 0) {
        return price
      }
      return task.initial_price
    },
    formatPrice(price: any = 0) {
      return formatPrice(price)
    },
    successAction() {
      this.newSuborder.start_date = this.suborderDates.start_date && this.suborderDates.start_date.valueOf()
      this.newSuborder.finish_date = this.suborderDates.finish_date && this.suborderDates.finish_date.valueOf()
      this.newSuborder.quantity = this.quantityToChange.replace(',', '.')
      this.$emit('successModal', this.newSuborder)
    }
  },
  computed: {
    workerLimitError() {
      let newSuborderPrice = parseFloat(this.newSuborder.price.replaceAll(' ', '').replace(',', '.'))
      newSuborderPrice = newSuborderPrice || 0
      if (this.tax) {
        const limit = this.suborder?.month_limit || this.suborder?.worker?.monthly_limit || 0
        return newSuborderPrice > limit
      } else {
        const limit = this.suborder?.month_limit_without_tax || this.suborder?.worker?.month_limit_without_tax || 0
        return newSuborderPrice > limit
      }
    },
    popupTitle() {
      if (this.title) {
        return this.title
      }
      return `Редактирование заказа ${this.orderid ? '№' + this.orderid : ''}`
    },
    orderid() {
      const id = this.suborder?.id
      return id || null
    },
    workerName() {
      const worker = this.suborder?.worker
      if (worker) {
        const name = worker?.worker_full_name
        return name
      }
      if (this.suborder?.last_name || this.suborder?.first_name || this.suborder?.middle_name) {
        return `${this.suborder.last_name || ''} ${this.suborder.first_name || ''} ${this.suborder.middle_name || ''}`
      }
      return ''
    },
    workerPhone() {
      if (this.suborder?.username) {
        return this.suborder.username
      }
      return ''
    }
  }
})
