import { ESuborderStatus, ETransactionsStatus, EOrderStatus, EDocsStatus } from '@/types/api-values'

const BLUE_COLOR = '#F3F5FE'
const ORANGE_COLOR = '#FF9900'
const GREEN_COLOR = '#D8F4BD'
const RED_COLOR = '#F01C2C'
const DEFAULT_COLOR = '#EEEEF2'

export const SERVER_COUNTRIES = {
  RUSSIA: 'РФ',
  BELARUS: 'РБ',
  ARMENIA: 'Армения',
  KAZAKHSTAN: 'Казахстан',
  KYRGYZSTAN: 'Кыргызская Республика',
  UKRAINE: 'Украина',
  DNR: 'Донецкая Народная Республика',
  LNR: 'Луганская Народная Республика'
}

export const SUBORDER_STATUS = {
  [ESuborderStatus.created]: 'Ожидает подписание',
  [ESuborderStatus.active]: 'В работе',
  [ESuborderStatus.work]: 'В работе',
  [ESuborderStatus.check]: 'На проверке',
  [ESuborderStatus.act]: 'Подписание акта',
  [ESuborderStatus.payment]: 'Ожидание выплаты',
  [ESuborderStatus.dispute]: 'Открыт спор',
  [ESuborderStatus.finished]: 'Завершен',
  [ESuborderStatus.cancelled]: 'Отменен'
}

export const SUBORDER_COLORS = {
  [ESuborderStatus.created]: BLUE_COLOR,
  [ESuborderStatus.active]: BLUE_COLOR,
  [ESuborderStatus.work]: BLUE_COLOR,
  [ESuborderStatus.check]: ORANGE_COLOR,
  [ESuborderStatus.act]: ORANGE_COLOR,
  [ESuborderStatus.payment]: GREEN_COLOR,
  [ESuborderStatus.finished]: GREEN_COLOR,
  [ESuborderStatus.dispute]: RED_COLOR,
  [ESuborderStatus.cancelled]: RED_COLOR
}

export const SUBORDER_CHANGE_TYPES = {
  CHANGE: 'change_suborder',
  RETURN: 'return_suborder',
  CANCEL: 'cancel_suborder'
}

export const SUBORDER_CHANGE_REASON = [
  {
    value: 'poor_quality_work',
    label: 'Некачественно выполненные работы'
  },
  {
    value: 'suborder_not_completed',
    label: 'Заказ выполнен не полностью'
  },
  {
    value: 'another_reason',
    label: 'Другая причина'
  }
]

export const TRANSACTION_STATUS = {
  [ETransactionsStatus.not_signed]: 'Акт не подписан',
  [ETransactionsStatus.awaiting]: 'Ожидает оплаты',
  [ETransactionsStatus.paid]: 'Оплачено',
  [ETransactionsStatus.cancelled]: 'Отмена',
  [ETransactionsStatus.sent]: 'Платёж отправлен',
  [ETransactionsStatus.error]: 'Ошибка',
  [ETransactionsStatus.rejected]: 'Отклонено из-за ошибки',
  [ETransactionsStatus.processing]: 'В обработке'
}

export const TRANSACTION_COLORS = {
  [ETransactionsStatus.not_signed]: RED_COLOR,
  [ETransactionsStatus.awaiting]: ORANGE_COLOR,
  [ETransactionsStatus.paid]: GREEN_COLOR,
  [ETransactionsStatus.cancelled]: RED_COLOR,
  [ETransactionsStatus.sent]: ORANGE_COLOR,
  [ETransactionsStatus.error]: RED_COLOR,
  [ETransactionsStatus.rejected]: RED_COLOR,
  [ETransactionsStatus.processing]: BLUE_COLOR
}

export const ORDER_STATUS = {
  [EOrderStatus.active]: 'Активный',
  [EOrderStatus.finished]: 'Завершен',
  [EOrderStatus.cancelled]: 'Отменён'
}

export const ORDER_COLORS = {
  [EOrderStatus.active]: BLUE_COLOR,
  [EOrderStatus.finished]: GREEN_COLOR,
  [EOrderStatus.cancelled]: DEFAULT_COLOR
}

export const DOCS_STATUS = {
  [EDocsStatus.converting_error]: 'Ошибка',
  [EDocsStatus.exists]: 'Есть',
  [EDocsStatus.active]: 'Действующий',
  [EDocsStatus.signed]: 'Подписан',
  [EDocsStatus.not_signed]: 'Не подписан',
  [EDocsStatus.finished]: 'Завершён',
  [EDocsStatus.terminated]: 'Расторгнут',
  [EDocsStatus.filed]: 'Подан',
  [EDocsStatus.rejected]: 'Отклонен',
  [EDocsStatus.declined_by_self_employee]: 'Отклонено исполнителем',
  [EDocsStatus.revoked]: 'Отозван'
}
