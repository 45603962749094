
import { Options, Vue } from 'vue-class-component'
import { API } from '@/utils/API'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { formatPrice, formatToServerDate, formatName } from '@/utils/helpers'
import debounce from '@/utils/debounce'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import Loader from '@/components/Loader/Loader.vue'
import { UTable, UModal, UButton, UInput, UISelect, UDateRange, UTooltip, UMultiselect } from 'unit-uikit'
import d from '@/dictionary'

@Options({
  name: 'FinanceArchive',
  components: {
    UISelect,
    UDateRange,
    EmptyPagePlaceholder,
    Loader,
    UTable,
    UModal,
    UButton,
    UInput,
    UTooltip,
    UMultiselect
  },
  data() {
    return {
      showPopup: false,
      successCreated: false,
      failedcreate: false,
      filterData: {},
      unloadingData: {},
      transactions: [],
      totalAmount: '',
      uploadName: '',
      creatingInProcess: false,
      loading: true,
      selectedObject: {
        id: 'allObjects',
        name: 'Показать все'
      },
      searchFirstName: '',
      searchLastName: '',
      objectOptions: [],
      filterDates: {
        start_date: null,
        finish_date: null
      },
      onlyXls: false,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      debounceLoadOrdersWithParams: null,
      d
    }
  },
  methods: {
    formatToServerDate,
    loadOrdersWithParams() {
      this.scrollData.requestCount = 0
      this.scrollData.page = 0
      this.transactions = []
      this.loadArchive()
    },
    async loadArchive() {
      if (this.transactions.length < this.scrollData.count) {
        let queryParams = ''
        if (this.searchFirstName) {
          queryParams += `&first_name=${this.searchFirstName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (this.searchLastName) {
          queryParams += `&last_name=${this.searchLastName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        const startDate = this.filterDates.start_date ? new Date(this.filterDates.start_date) : null
        const finishDate = this.filterDates.finish_date ? new Date(this.filterDates.finish_date) : null
        if (startDate) {
          queryParams += `&start_date=${formatToServerDate(startDate)}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (finishDate) {
          queryParams += `&end_date=${formatToServerDate(finishDate)}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        if (this.selectedObject && this.selectedObject?.id !== 'allObjects') {
          queryParams += `&object_id=${this.selectedObject.id}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&status=paid` + queryParams
        const response = await this.axios.get(API.GET_TRANSACTIONS_ARCHIVE + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.totalAmount = data.total_amount
          this.scrollData.requestCount = data.count
          const transactions = data.results
          this.transactions.push(...transactions)
        }
      }
    },
    downloadFiles() {
      this.uploadName = `Выгрузка_выплаты_${moment(new Date()).format('DD.MM.YY_HH:mm:ss')}`
      this.showPopup = true
    },
    createExcel() {
      this.onlyXls = true
      this.createUploads()
    },
    createUploads() {
      this.creatingInProcess = true

      let queryString = ''

      if (this.searchFirstName) {
        queryString += `first_name=${this.searchFirstName}`
      }

      if (this.searchLastName) {
        queryString += `${queryString ? '&' : ''}last_name=${this.searchLastName}`
      }

      if (this.selectedObject && this.selectedObject?.id !== 'allObjects') {
        queryString += `${queryString ? '&' : ''}object_id=${this.selectedObject.id}`
      }

      const startDate = this.filterDates.start_date ? new Date(this.filterDates.start_date) : null
      const finishDate = this.filterDates.finish_date ? new Date(this.filterDates.finish_date) : null
      if (startDate) {
        queryString += `${queryString ? '&' : ''}start_date=${formatToServerDate(startDate)}`
      }
      if (finishDate) {
        queryString += `${queryString ? '&' : ''}end_date=${formatToServerDate(finishDate)}`
      }

      this.axios
        .post(
          API.FINANCIAL_UNLOADING + '?' + queryString,
          {
            unloading_name: this.uploadName,
            query_link: API.GET_ALL_DOCS + '?' + queryString,
            only_xls: this.onlyXls
          }
        )
        .then(() => {
          this.showPopup = false
          this.successCreated = true
          this.creatingInProcess = false
          this.onlyXls = false
        })
        .catch((e: any) => {
          Sentry.captureException(e)
          this.failedcreate = true
          this.showPopup = false
          this.creatingInProcess = false
          this.onlyXls = false
        })
    },
    formatName,
    formatDate(date: any) {
      return moment(new Date(date)).format('DD.MM.YY HH:mm')
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    }
  },
  created() {
    this.selectedObject = {
      id: 'allObjects',
      name: 'Показать все'
    }

    this.axios
      .get(API.GET_OBJECT)
      .then((res: { data: any }) => {
        const objects = res.data
        this.allObjects = res.data
        this.objectOptions = [
          {
            id: 'allObjects',
            name: 'Показать все'
          },
          ...objects
        ]
      })
      .catch((e: any) => {
        console.error(e)
      })

    this.axios
      .get(API.GET_TRANSACTIONS_ARCHIVE + `?page=${this.scrollData.page}&page_size=${this.scrollData.size}&status=paid`)
      .then((response: { data: any }) => {
        this.totalAmount = response.data.total_amount
        this.scrollData.count = response.data.count
        this.transactions = response.data.results || []
        this.loading = false
      })
      .catch((error: any) => {
        console.error(error)
        this.loading = false
      })
  },
  watch: {
    'filterDates.start_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    'filterDates.finish_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchFirstName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchLastName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    selectedObject() {
      if (this.selectedObject?.id !== 'allObjects') {
        const _object = this.allObjects.filter((item: any) => {
          return item.id + '' === this.selectedObject?.id + ''
        })
        const projectId = _object && _object[0] && _object[0].project?.id
        if (projectId && this.selectedProject + '' !== projectId + '') {
          this.selectedProject = projectId
        }
      }

      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    }
  }
})
export default class FinanceArchive extends Vue {}
