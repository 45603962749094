
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  data() {
    return {
      notVerifiedCompany: false
    }
  },
  created() {
    this.$route.path === '/not_verified_company' ? (this.notVerifiedCompany = true) : (this.notVerifiedCompany = false)
  },
  computed: {
    hideitem() {
      return this.$route.name === 'staff.check'
    }
  }
})
export default class Login extends Vue {}
