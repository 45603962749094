
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    label: { type: String, default: 'Регистрация' },
    showCloseBtn: { type: Boolean, default: false },
    showActionBtns: { type: Boolean, default: false },
    backURL: { type: [String, Number], default: -1 },
    hideBackURL: { type: Boolean, default: false },
    backCustomAction: { type: Function }
  },
  methods: {
    backAction() {
      if (this.backCustomAction) {
        this.backCustomAction()
        return
      }
      if (this.backURL === -1) {
        this.$router.go(this.backURL)
        return
      }
      this.$router.push({
        name: this.backURL
      })
    }
  }
})
export default class BackMobileBtn extends Vue {}
