
import { PropType, defineComponent } from 'vue'
import moment from 'moment'
import axios from 'axios'
import { API } from '@/utils/API'
import { APIAddWorkersBudget, APIAddWorkersOrder } from '@/interfaces/main'
import { formatPrice } from '@/utils/helpers'
import { UTable, UISelect, UInput } from 'unit-uikit'

export default defineComponent({
  name: 'AddOrder3',
  components: {
    UTable,
    UISelect,
    UInput
  },
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    budget: {
      type: Object as PropType<APIAddWorkersBudget>,
      required: true
    }
  },
  data() {
    return {
      project: '',
      object: '',
      companyInfo: {} as any,
      oneWorkerSumWithTax: null,
      unitTypesList: [],
      templates: {
        agreement_template: [],
        order_template: [],
        acceptance_certificate_template: []
      }
    }
  },
  methods: {
    formatPercentage(value: any) {
      if (value) {
        return ((value * 100).toFixed(2) + '').replace('.', ',')
      }
      return '0,00'
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatDate(date: any) {
      return moment(new Date(date)).format('DD.MM.yy')
    },
    getWorkerName(lastname: string, name: string, middlename: string) {
      return `${lastname} ${name} ${middlename || ''}`
    },
    getFormatedDate(datetime: number | string) {
      const date = new Date(datetime)
      return this.formatDate(date)
      // return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
    },
    getUnitType(type: number) {
      const result = this.unitTypesList
        .filter((item: any) => {
          return item.id + '' === type + ''
        })
        .map((item: any) => {
          return item.name
        })
      return (result && result[0]) || ''
    },
    getTemplateFile(id: string | number, arrTemplates: [] | any) {
      let templateFile = ''
      arrTemplates.forEach((template: { id: string | number; name: string; file: string }) => {
        if (template.id + '' === id + '') {
          templateFile = template.file
        }
      })
      return templateFile
    }
  },
  computed: {
    budgetPlusTaxes() {
      return this.budget.budgetPlusTaxes ? (this.budget.budgetPlusTaxes + '').replaceAll(',', '.') : 0
    },
    workerWithTax() {
      const amount = +this.budget.oneWorkerSum + +this.workerTax
      const workerWithTax = amount ? amount.toFixed(2) : 0
      return workerWithTax
    },
    outcomeTax(): any {
      // Итого на исполнителя (налог + %)”=(Исполнителю “на руки“)*(1+0,06)*(1+0,0475)
      if (this.budget.workersAmount && this.budget.budgetPlusTaxes) {
        const tax = this.budget.budgetPlusTaxes / this.budget.workersAmount
        const fullTaxes = tax ? tax.toFixed(2) : 0
        return fullTaxes
      }
      return 0
    },
    fullOutcomeTax() {
      // (Итого налог + % на всех)=(Всего налогов самозанятых 6%)+(Всего комиссия платформы 4,75%)
      return (+this.allWorkersTaxes + +this.finalPlatformCommision).toFixed(2)
    },
    platformComission() {
      // (Комиссия платформы 4,75%) = (Исполнителю “на руки“)*(1+0,06)*0,0475
      if (this.companyInfo && this.outcomeTax) {
        const commision = this.outcomeTax * this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    finalPlatformCommision() {
      // (Всего комиссия платформы 4,75%) = (Всем исполнителям “на руки”)*(1+0,06)*0,0475
      if (this.companyInfo && this.budget.allWorkersSum) {
        const commision =
          (this.budget.allWorkersSum / (1 - this.companyInfo['self-employed_tax']) / (1 - this.companyInfo.unitpay_commission)) *
          this.companyInfo.unitpay_commission
        return commision ? commision.toFixed(2) : 0
      }
      return 0
    },
    workerTax() {
      // (Налог самозанятого 6%) = (Исполнителю “на руки“)*0,06
      if (this.companyInfo && this.budget.oneWorkerSum) {
        const tax = (this.budget.oneWorkerSum / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return tax ? tax.toFixed(2) : 0
      }
      return 0
    },
    allWorkersTaxes() {
      // (Всего налогов самозанятых 6%) = (Всем исполнителям “на руки”) * 0,06
      if (this.budget.allWorkersSum) {
        const taxes = (this.budget.allWorkersSum / (1 - this.companyInfo['self-employed_tax'])) * this.companyInfo['self-employed_tax']
        return taxes ? taxes.toFixed(2) : 0
      }
      return 0
    },
    freeBalance() {
      return this.companyInfo ? this.companyInfo.object_budget - this.companyInfo.object_reserve : 0
    }
  },
  created() {
    const objectId: any = this.order.object
    axios
      .get(API.GET_FINANCIAL_INFO(objectId))
      .then((response: any) => {
        this.companyInfo = response.data
      })
      .catch((e: any) => {
        console.error(e)
      })

    axios.get(API.GET_UNIT_TYPES).then((res: any) => {
      this.unitTypesList = res.data
    })

    const getObjects = axios.get(API.GET_OBJECT)
    const getOrderData = axios.get(API.GET_ORDER_PREPARATION)
    getObjects
      .then((response: any) => {
        const objects = response.data
        const object = objects?.filter((obj: any) => {
          return obj.id + '' === this.order?.object + ''
        })
        this.object = object[0]?.name
        this.project = object[0]?.project?.name
      })
      .catch((error: any) => {
        console.error(error)
      })

    getOrderData
      .then((response: any) => {
        const agreementTemplateArr = response.data?.agreement_templates
        this.templates.agreement_template = agreementTemplateArr

        const orderTemplateArr = response.data?.order_templates
        this.templates.order_template = orderTemplateArr

        const certificateTemplateArr = response.data?.accpetance_certificate_templates
        this.templates.acceptance_certificate_template = certificateTemplateArr
      })
      .catch((error: any) => {
        console.error(error)
      })
  }
})
