
import { Options, Vue } from 'vue-class-component'
import { API } from '@/utils/API'
import Loader from '@/components/Loader/Loader.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import { UTable, UInput, UButton, UModal } from 'unit-uikit'

@Options({
  components: {
    UTable,
    Loader,
    UInput,
    UButton,
    UModal,
    EmptyPagePlaceholder
  },
  emits: ['updateCounter'],
  data() {
    return {
      loading: false,
      trustedPersons: [],
      selectAll: false,
      showModal: false,
      showSuccessModal: false,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      trustedPersonIdToDelete: ''
    }
  },
  mounted() {
    this.loadTrustedPersons()
  },
  methods: {
    async loadTrustedPersons() {
      this.loading = true
      this.axios
        .get(API.GET_TRUSTED_PERSONS)
        .then((response: any) => {
          this.trustedPersons = response.data
          this.loading = false
        })
        .catch((error: any) => {
          this.loading = false
          console.error(error)
        })
    },
    openEditMenu(id: string | number) {
      this.trustedPersons = this.trustedPersons.map((item: any) => {
        const itemId = item.id
        if (id + '' === itemId + '') {
          item.showEditMenu = !item.showEditMenu
        } else {
          item.showEditMenu = false
        }
        return item
      })
    },
    closeEditMenu() {
      this.trustedPersons = this.trustedPersons.map((item: any) => {
        item.showEditMenu = false
        return item
      })
    },
    showModalDeleteTrustedPerson(id: string | number) {
      this.showModal = true
      this.trustedPersonIdToDelete = id
    },
    deleteTrustedPerson(id: string | number) {
      this.showSuccessModal = true
      this.axios
        .delete(API.TRUSTED_PERSON(id))
        .then(() => {
          this.showModal = false
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    afterDeleteTrustedPerson() {
      this.showSuccessModal = false
      this.showModal = false
      this.loadTrustedPersons()
    }
  }
})
export default class OmvdTrustedPersons extends Vue {}
