
import { Options, Vue } from 'vue-class-component'
import { API } from '@/utils/API'
import { getSuggestionsByInn, getBankByBic } from '@/utils/dadata'
import useValidate from '@vuelidate/core'
import { maxLength, minLength, requiredIf } from '@vuelidate/validators'
import AuthLayout from '@/views/Registration/AuthLayout.vue'
import { UButton, UInput, UICheckbox } from 'unit-uikit'
import BackMobileBtn from '@/components/UIElements/BackMobileBtn.vue'

const fioValidation = (fio: string) =>
  (fio.split(' ').length === 3 && fio.split(' ')[2] !== '') ||
  (fio.split(' ').length === 2 && fio.split(' ')[3] !== '' && fio.split(' ')[1] !== '')

const fioValidationIE = (fio: string) => {
  const _parts = fio.trim().split(' ')
  return _parts.length >= 3 && _parts[0].length && _parts[1].length && _parts[2].length
}

@Options({
  components: {
    UICheckbox,
    AuthLayout,
    UButton,
    UInput,
    BackMobileBtn
  },
  data() {
    return {
      v$: useValidate(),
      step: 0,
      orgTypeBtns: true,
      isIE: false,
      isLLC: false,
      isRegDataComplete: false,
      isFioEqual: false,
      apiErrorText: '',
      errorMsgINN: '',
      errorMsgOGRN: '',
      regDataIE: {
        fio: '',
        phone: localStorage.getItem('userPhone'),
        title: '',
        number: '',
        INN: '',
        bankTitle: '',
        checkingAccount: '',
        correspondentAccount: '',
        BIC: ''
      },
      regDataLLC: {
        directorFio: '',
        phone: localStorage.getItem('userPhone'),
        title: '',
        number: '',
        INN: '',
        personalFio: '',
        bankTitle: '',
        checkingAccount: '',
        correspondentAccount: '',
        BIC: '',
        kpp: '',
        short_name: '',
        address: '',
        registered_address: '',
        okved: ''
      }
    }
  },
  validations() {
    return {
      regDataIE: {
        fio: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          maxLength: maxLength(150),
          fioValidationIE
        },
        phone: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          minLength: minLength(16)
        },
        title: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          maxLength: maxLength(150)
        },
        number: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          minLength: minLength(15)
        },
        INN: {
          requiredIfIE: requiredIf(this.isIE && this.step === 1),
          minLength: minLength(12)
        },
        bankTitle: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          maxLength: maxLength(150)
        },
        checkingAccount: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          minLength: minLength(20)
        },
        correspondentAccount: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          minLength: minLength(20)
        },
        BIC: {
          requiredIfIE: requiredIf(this.isIE && this.step === 2),
          minLength: minLength(9)
        }
      },
      regDataLLC: {
        directorFio: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          maxLength: maxLength(150),
          fioValidation
        },
        phone: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          minLength: minLength(12)
        },
        title: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          maxLength: maxLength(150)
        },
        number: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          minLength: minLength(13)
        },
        INN: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          minLength: minLength(10)
        },
        personalFio: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 1),
          maxLength: maxLength(150),
          fioValidation
        },
        bankTitle: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          maxLength: maxLength(150)
        },
        checkingAccount: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          minLength: minLength(20)
        },
        correspondentAccount: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          minLength: minLength(20)
        },
        BIC: {
          requiredIfLLC: requiredIf(this.isLLC && this.step === 2),
          minLength: minLength(9)
        }
      }
    }
  },
  methods: {
    getSuggestionsByInn,
    getBankByBic,
    signUpIE() {
      this.step = 1
      this.orgTypeBtns = false
      this.isIE = true
      this.isLLC = false
    },
    signUpLLC() {
      this.step = 1
      this.orgTypeBtns = false
      this.isLLC = true
      this.isIE = false
    },
    nextStep() {
      // this.v$.$validate()

      // if (!this.v$.$error) {
      this.step = 2
      // }
    },
    prevStep() {
      if (this.step === 0) {
        this.$router.push({
          name: 'login'
        })
        return
      }
      if (this.step === 2) {
        this.step = 1
      } else if (this.step === 1) {
        this.isLLC = false
        this.isIE = false
        this.step = 0
        this.orgTypeBtns = true
      }
    },
    directorFioToPersonalFio() {
      if (this.regDataLLC.directorFio) {
        this.regDataLLC.personalFio = this.regDataLLC.directorFio
      }
    },
    regDataComplete() {
      this.v$.$validate()
      const fio = this.isIE ? this.regDataIE.fio.split(' ') : this.regDataLLC.directorFio.split(' ')
      const companyRepresentativeFio = this.regDataLLC.personalFio.split(' ')

      const regData: any = {
        phone: this.isIE ? this.regDataIE.phone.replaceAll('-', '') : this.regDataLLC.phone.replaceAll('-', ''),
        code: sessionStorage.getItem('regSmsCode'), // TODO
        account_type: 'customer',
        username: this.isIE ? this.regDataIE.phone.replaceAll('-', '') : this.regDataLLC.phone.replaceAll('-', ''),
        first_name: fio[1],
        last_name: fio[0],
        middle_name: fio[2],
        representative_first_name: companyRepresentativeFio[1] || fio[1],
        representative_last_name: companyRepresentativeFio[0] || fio[0],
        representative_middle_name: companyRepresentativeFio[2] || fio[2],
        company_name: this.isIE ? this.regDataIE.title : this.regDataLLC.title,
        ogrn: this.isIE ? this.regDataIE.number : this.regDataLLC.number,
        inn: this.isIE ? this.regDataIE.INN : this.regDataLLC.INN,
        bank: this.isIE ? this.regDataIE.bankTitle : this.regDataLLC.bankTitle,
        bik: this.isIE ? this.regDataIE.BIC : this.regDataLLC.BIC,
        checking_account: this.isIE ? this.regDataIE.checkingAccount : this.regDataLLC.checkingAccount,
        correspondent_account: this.isIE ? this.regDataIE.correspondentAccount : this.regDataLLC.correspondentAccount,
        type: this.isIE ? 'Индивидуальный Предприниматель' : 'Общество с Ограниченной Ответственностью',
        kpp: this.regDataLLC.kpp,
        short_name: this.regDataLLC.short_name ? this.regDataLLC.short_name : this.regDataLLC.title,
        address: this.regDataLLC.address,
        registered_address: this.regDataLLC.registered_address,
        okved: this.regDataLLC.okved
      }

      if (!this.regDataLLC.kpp) {
        delete regData.kpp
      }
      if (this.isIE) {
        delete regData.kpp
        delete regData.short_name
        delete regData.address
        delete regData.registered_address
        delete regData.okved
      }

      this.axios
        .post(API.SIGNUP_ENTITY, regData)
        .then((response: any) => {
          if (response.status === 200) {
            this.isRegDataComplete = true
            this.apiErrorText = ''
          }
        })
        .catch((error: any) => {
          console.error(error.response)
          const data = error.response.data
          const text = (data.username && data.username[0]) || (data.okved && data.okved[0])
          this.apiErrorText = text
        })
    },
    checkINNorOGRN(data: string, isINN: boolean, isIE: boolean) {
      const innLengthIE = 12
      const ogrnLengthIE = 15
      const innLengthLLC = 10
      const ogrnLengthLLC = 13
      if (data && (data.length < innLengthIE || data.length < ogrnLengthIE || data.length < innLengthLLC || data.length < ogrnLengthLLC)) {
        isINN ? (this.errorMsgINN = '') : (this.errorMsgOGRN = '')
      }
      if (
        (data && data.length === innLengthIE && isIE) ||
        (data && data.length === ogrnLengthIE && isIE) ||
        (data && data.length === innLengthLLC && !isIE) ||
        (data && data.length === ogrnLengthLLC && !isIE)
      ) {
        this.axios
          .post(API.CHECK_INN_OGRN, { [isINN ? 'inn' : 'ogrn']: data })
          .then(() => {
            isINN ? (this.errorMsgINN = '') : (this.errorMsgOGRN = '')
          })
          .catch((error: any) => {
            if (error && error.response.data) {
              isINN
                ? (this.errorMsgINN = error.response.data.non_field_errors[0].inn)
                : (this.errorMsgOGRN = error.response.data.non_field_errors[0].ogrn)
            }
          })
      }
    }
  },
  computed: {
    getDataByINN() {
      if (this.regDataLLC.INN.length === 10) {
        getSuggestionsByInn(this.regDataLLC.INN, (response: any) => {
          let orgData = response.data.suggestions?.[0]?.data
          this.regDataLLC.title = orgData.name?.short_with_opf
          this.regDataLLC.number = orgData.ogrn
          this.regDataLLC.address = orgData.address?.value
          this.regDataLLC.registered_address = orgData.address?.unrestricted_value
          this.regDataLLC.short_name = orgData.name?.short_with_opf
          this.regDataLLC.kpp = orgData.kpp

          const okvedRegexp = /^\d{2}(\.\d{1,2})?(\.\d{1,2})?$/
          if (orgData.okved.match(okvedRegexp)) {
            this.regDataLLC.okved = orgData.okved
          } else {
            this.regDataLLC.okved = ''
          }
        })
      }
    },
    getDataByBic() {
      if (this.regDataLLC.BIC.length === 9) {
        getBankByBic(this.regDataLLC.BIC, (response: any) => {
          this.regDataLLC.bankTitle = response.data.suggestions?.[0]?.data.name?.payment
          this.regDataLLC.correspondentAccount = response.data.suggestions?.[0]?.data.correspondent_account
        })
      } else if (this.regDataIE.BIC.length === 9) {
        getBankByBic(this.regDataIE.BIC, (response: any) => {
          this.regDataIE.bankTitle = response.data.suggestions?.[0]?.data.name?.payment
          this.regDataIE.correspondentAccount = response.data.suggestions?.[0]?.data.correspondent_account
        })
      }
    },
    regType() {
      let title = ''
      if (!this.isIE && !this.isLLC) {
        title = 'Регистрация'
      } else if (this.isIE && !this.isLLC) {
        title = 'Регистрация индивидуального предпринимателя'
      } else if (!this.isIE && this.isLLC) {
        title = 'Регистрация юридического лица'
      }
      return title
    }
  }
})
export default class Registration extends Vue {}
