
import { Options, Vue } from 'vue-class-component'
import { SUBORDER_CHANGE_REASON, SUBORDER_CHANGE_TYPES } from '@/utils/consts'
import moment from 'moment'

@Options({
  props: {
    comment: Object,
    worker: String
  },
  methods: {
    formatDate(date: any) {
      return moment(new Date(date)).format('DD.MM.YY HH:mm')
    },
    getFileUrl(item: any) {
      const URL_CLEAR = process.env.VUE_APP_URL
      const file = item?.file
      if (file) {
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    },
    getFileName(item: any) {
      const file = item?.file
      if (file) {
        function replacer(match: any, p1: any, p2: any, p3: any, p4: any) {
          return [[p2, p4]].join('.')
        }
        return file.replace(/(.*\/)([a-zA-Z]*)(.*)(.[a-z]{3,4})$/, replacer)
      }
      return ''
    }
  },
  computed: {
    reason() {
      const value = this.comment?.reason || []
      const result: any = []
      value.forEach((r: any) => {
        const filteredReason = SUBORDER_CHANGE_REASON.filter((item: any) => item.value === r)
        const res = (filteredReason && filteredReason[0] && filteredReason[0].label) || 'Другая причина'
        result.push(res)
      })
      return result && result.length ? result.join(', ') : 'Другая причина'
    },
    title() {
      if (this.comment?.isResult) {
        return 'Дата загрузки'
      }
      if (this.comment?.type === SUBORDER_CHANGE_TYPES.RETURN) {
        return 'Возврат в работу'
      }
      if (this.comment?.type === SUBORDER_CHANGE_TYPES.CHANGE) {
        return 'Изменение цены или объёма'
      }
      return ''
    },
    commentTitle() {
      if (this.comment?.isResult) {
        return 'Комментарий от исполнителя'
      }
      return 'Комментарий заказчика'
    },
    workerTitle() {
      if (this.comment?.isResult) {
        return this.worker
      }
      return 'UNIT'
    }
  }
})
export default class CommentResultItem extends Vue {}
