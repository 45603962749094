
import { Options, Vue } from 'vue-class-component'
import { UInput, UButton, UMultiselect, UICheckbox, UModal } from 'unit-uikit'
import { formatDateForQueryString, formatToServerDateNotifications } from '@/utils/helpers'
import { API } from '@/utils/API'
import { useUiStore } from '@/stores/ui'

function checkValidDate(date: string) {
  return date.length === 10
}

function getDocCategories(route: string) {
  switch (route) {
    case 'omvd.notifications':
      return ['end_work_notification']
    case 'omvd.history':
      return ['start_work_notification', 'end_work_notification']
    case 'omvd.all':
      return ['start_work_notification']

    default:
      return []
  }
}

function getDocCertificates(route: string) {
  switch (route) {
    case 'omvd.notifications':
      return false
    case 'omvd.history':
    case 'omvd.all':
      return true

    default:
      return false
  }
}

function getDocAgreements(route: string) {
  switch (route) {
    case 'omvd.notifications':
      return false
    case 'omvd.history':
    case 'omvd.all':
      return true

    default:
      return true
  }
}

@Options({
  components: {
    UInput,
    UButton,
    UMultiselect,
    UICheckbox,
    UModal
  },
  data() {
    return {
      uiStore: useUiStore(),
      application_sending_date: '',
      onlyNotificationsUpload: [],
      trustedPersonsList: [],
      selectedTrustedPerson: ''
    }
  },
  mounted() {
    this.getTrustedPersons()
  },
  methods: {
    formatToServerDateNotifications,
    formatDateForQueryString,
    getTrustedPersons() {
      this.axios
        .get(API.GET_TRUSTED_PERSONS)
        .then((response: any) => {
          this.trustedPersonsList = response.data
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    getTrustedPersonsListSelectOptionsNames({ last_name, first_name, middle_name }: any) {
      return `${last_name || ''} ${first_name || ''} ${middle_name || ''}`
    },
    createNotificationsBundle() {
      const selectAllSwitcher = this.uiStore.selectAllNotifications
      const queryParams = this.uiStore.selectAllNotificationsQueryParams || ''

      // 3. Выбрали все
      // "add_certificates" - false,
      // "category" = [] - заполняем в зависимости от страницы
      // "query_params" - заполняем то что после /, если есть
      // "all" = True

      let ids = this.uiStore.checkedNotifications || []
      ids = ids.reduce((prev: any, next: any) => {
        return [...prev, ...next.split(',')]
      }, [])

      if ((ids && ids.length) || selectAllSwitcher) {
        const route = this.$route.name
        const category = getDocCategories(route)
        const queryLink = queryParams

        let payload = {
          query_link: API.SET_DOCS_EXPORT + '?' + queryLink,
          name: 'Уведомления ' + formatDateForQueryString(new Date()),
          // document_amount: ids.length,
          period_begin: formatDateForQueryString(0),
          period_end: formatDateForQueryString(new Date()),
          add_certificates: getDocCertificates(route),
          add_agreements: getDocAgreements(route),
          category,
          has_procuration: !this.onlyNotificationsUpload.length,
          query_params: '?' + queryParams,
          ids: selectAllSwitcher ? [] : ids || [],
          all_docs: selectAllSwitcher || false
        }

        if (!this.onlyNotificationsUpload.length) {
          payload = {
            ...payload,
            ...{
              trusted_person: this.selectedTrustedPerson.id,
              application_sending_date: formatToServerDateNotifications(this.application_sending_date)
            }
          }
        }

        this.axios
          .post(API['SET_DOCS_EXPORT_ALL'] + '?' + queryLink, payload)
          .then(() => {
            this.$emit('success')
          })
          .catch(() => {
            this.$emit('error')
          })
      }
    }
  },
  computed: {
    disableInputs() {
      return !!this.onlyNotificationsUpload.length
    },
    isDataReady() {
      const isOnlyNotifications = !!this.onlyNotificationsUpload.length
      const docsReady = checkValidDate(this.application_sending_date)
      return isOnlyNotifications || (docsReady && this.selectedTrustedPerson.id)
    },
    closeModal() {
      this.$emit('close')
    }
  }
})
export default class DownloadNotifications extends Vue {}
