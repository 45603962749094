
import { PropType, defineComponent } from 'vue'
import axios from 'axios'
import { API } from '@/utils/API'
import { APIAddWorkersOrder } from '@/interfaces/main'
import { UTooltip, UDateRange, UISelect, UInput, UTextarea, UMultiselect, USwitch } from 'unit-uikit'
import Loader from '@/components/Loader/Loader.vue'
import d from '@/dictionary'

export default defineComponent({
  name: 'AddOrder1',
  components: {
    UTooltip,
    Loader,
    UDateRange,
    UISelect,
    UInput,
    UTextarea,
    UMultiselect,
    USwitch
  },
  props: {
    order: {
      type: Object as PropType<APIAddWorkersOrder>,
      required: true
    },
    v$: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      projects: [],
      allObjects: [],
      objects: [] as any[],
      projectsList: [],
      unitTypesList: [],
      photoRequired: 0,
      photoAfterWorksOptions: [
        {
          id: 1,
          name: 'Да'
        },
        {
          id: 0,
          name: 'Нет'
        }
      ],
      templates: {
        agreement_template: [],
        order_template: [],
        acceptance_certificate_template: []
      },
      certificateOptions: [
        {
          id: '',
          name: 'Нет'
        }
      ],
      equipment: [
        {
          id: '',
          name: 'Нет'
        }
      ],
      booleans: [
        {
          id: 'да',
          name: 'Да'
        },
        {
          id: 'нет',
          name: 'Нет'
        }
      ],
      units: [
        {
          id: 1,
          name: 'кв.м'
        },
        {
          id: 2,
          name: 'м'
        },
        {
          id: 3,
          name: 'кг'
        },
        {
          id: 4,
          name: 'куб.м'
        }
      ],
      d
    }
  },
  methods: {
    selectAction(action: any) {
      if (action.id === '') {
        setTimeout(() => {
          this.order.job_certs_requirements = [{ id: '', name: 'Нет' }]
        }, 0)
        return
      }
      if (
        action.id !== '' &&
        this.order.job_certs_requirements &&
        this.order.job_certs_requirements?.length === 1 &&
        this.order.job_certs_requirements[0].id === ''
      ) {
        this.order.job_certs_requirements = [action]
        setTimeout(() => {
          this.order.job_certs_requirements = [action]
        }, 0)
      }
    },
    selectActionEquipment(action: any) {
      if (action.id === '') {
        setTimeout(() => {
          this.order.equipment = [{ id: '', name: 'Нет' }]
        }, 0)
        return
      }
      if (action.id !== '' && this.order.equipment && this.order.equipment?.length === 1 && this.order.equipment[0].id === '') {
        this.order.equipment = [action]
        setTimeout(() => {
          this.order.equipment = [action]
        }, 0)
      }
    },
    getWorkTypes() {
      axios.get(API.GET_WORK_TYPES).then((res: { data: any }) => {
        this.projectsList = res.data.results
      })
    }
  },
  created() {
    this.order.special_requirements = this.order.special_requirements ? this.order.special_requirements : 'Нет'
    this.order.withholding_tax = !this.order.withholding_tax ? false : this.order.withholding_tax
    this.photoRequired = this.order.photo_after_completion ? 1 : 0
    this.getWorkTypes()
    const getOrderData = axios.get(API.GET_ORDER_PREPARATION)
    const getObjects = axios.get(API.GET_OBJECT)

    axios.get(API.GET_UNIT_TYPES).then((res: any) => {
      this.unitTypesList = res.data
    })

    Promise.all([getOrderData, getObjects])
      .then((results) => {
        results.forEach((result: any) => {
          const responseURL = result?.request?.responseURL
          if (responseURL.indexOf('object') !== -1) {
            this.allObjects = result.data

            const projectId = this.order.project
            if (projectId) {
              this.objects = result.data.filter((o: any) => {
                return o?.project?.id + '' === projectId + ''
              })
            }
            const objectId = this.order.object
            if (objectId) {
              result.data.forEach((obj: any) => {
                if (obj.id + '' === objectId + '') {
                  this.order.address = obj?.address
                }
              })
            }
          } else {
            this.projects = result.data?.main || []
            const projectId = this.order.project

            this.projects = this.projects.filter((project: any) => {
              const objectIsNotArchive = project.object_set?.find((o: any) => {
                return o?.status !== 'archive'
              })
              return !!project.objects && objectIsNotArchive
            })

            const projectIsNotArchive = this.projects.find((p: any) => {
              return p.id + '' === projectId + ''
            })

            if (!projectIsNotArchive) {
              this.order.project = null
            }

            const agreementTemplateArr = result.data?.agreement_templates
            this.templates.agreement_template = agreementTemplateArr
            if (agreementTemplateArr?.length === 1 && !this.order.agreement_template) {
              this.order.agreement_template = agreementTemplateArr[0]?.id
            }

            const orderTemplateArr = result.data?.order_templates
            this.templates.order_template = orderTemplateArr
            if (orderTemplateArr?.length === 1 && !this.order.order_template) {
              this.order.order_template = orderTemplateArr[0]?.id
            }

            const certificateTemplateArr = result.data?.accpetance_certificate_templates
            if (certificateTemplateArr) {
              this.templates.acceptance_certificate_template = certificateTemplateArr
              if (certificateTemplateArr?.length === 1 && !this.order.acceptance_certificate_template) {
                this.order.acceptance_certificate_template = certificateTemplateArr[0]?.id
              }
            }

            const certificateRequirments = result.data?.job_certs_requirements
            if (certificateRequirments) {
              this.certificateOptions = this.certificateOptions.concat(certificateRequirments)
              if (certificateRequirments?.length === 1 && !this.order.job_certs_requirements) {
                this.order.job_certs_requirements = certificateRequirments[0]
              } else if (!this.order.job_certs_requirements) {
                this.order.job_certs_requirements = [{ id: '', name: 'Нет' }]
              }
            }

            const technicsReqs = result.data?.equipment
            if (technicsReqs) {
              this.equipment = this.equipment.concat(technicsReqs)
              if (technicsReqs?.length === 1 && !this.order.equipment?.length) {
                this.order.equipment = technicsReqs[0]
              } else if (!this.order.equipment?.length) {
                this.order.equipment = [{ id: '', name: 'Нет' }]
              }
            } else {
              this.order.equipment = [{ id: '', name: 'Нет' }]
            }

            // this.order.monthly_limit_without_tax = result.data.monthly_limit_without_tax
            // this.order.monthly_limit_with_tax = result.data.monthly_limit_with_tax
            this.order.monthly_limit_without_tax = 600000
            this.order.monthly_limit_with_tax = 250000
          }
          this.loading = false
        })
      })
      .catch((e) => {
        this.loading = false
        console.error(e)
      })
  },
  watch: {
    photoRequired() {
      this.order.photo_after_completion = !!+this.photoRequired
    },
    'order.object'() {
      const id = this.order.object
      if (id) {
        const filteredOjbect = this.allObjects.filter((o: any) => {
          return o?.id + '' === id + ''
        })
        const obj: any = filteredOjbect && filteredOjbect[0]
        this.order.address = obj?.address
      }
    },
    'order.project'() {
      const project = this.order.project

      this.order.object = null
      if (project) {
        this.objects = this.allObjects.filter((o: any) => {
          return o?.project?.id + '' === project + ''
        })
      } else {
        this.objects = []
      }
    }
  }
})
