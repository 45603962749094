
import { Options, Vue } from 'vue-class-component'
import CommentResultItem from './CommentResultItem.vue'
import FilesResultsItem from './FilesResultsItem.vue'
import { UModal } from 'unit-uikit'

@Options({
  components: {
    CommentResultItem,
    FilesResultsItem,
    UModal
  },
  props: {
    show: Boolean,
    closeAction: Function,
    returnToWorkAction: Function,
    results: Array,
    returns: Array,
    worker: String
  },
  data() {
    return {
      activeTab: 'results'
    }
  },
  methods: {
    isActiveTab(tab: string) {
      return tab === this.activeTab ? 'router-link-exact-active' : ''
    },
    setActiveTab(tab: string) {
      this.activeTab = tab
    }
  },
  computed: {
    workResults() {
      return this.results
        .map((item: any) => {
          item.isResult = true
          return item
        })
        .reverse()
    },
    commentHistory() {
      const results = this.workResults
      const history = [...results, ...this.returns].sort((a: any, b: any) => {
        return new Date(b.created).valueOf() - new Date(a.created).valueOf()
      })
      return history
    },
    title() {
      return this.activeTab === 'results' ? 'Результат работы' : 'Прошлые комментарии'
    }
  }
})
export default class WorkResults extends Vue {}
