
import { Options, Vue } from 'vue-class-component'
import moment from 'moment'

@Options({
  components: {},
  props: {
    worker: Object
  },
  methods: {
    formatDate(date: any) {
      try {
        return moment(new Date(date)).format('DD.MM.YYYY')
      } catch (e) {
        return ''
      }
    }
  },
  computed: {
    agreement() {
      const agreement = this.worker?.agreement
      return agreement && agreement[0]
    }
  }
})
export default class WorkerAggrementDesk extends Vue {}
