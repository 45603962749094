<template>
  <WarningProcuration v-if="procurationExpiring" :daysLeft="procurationDaysLeft" />

  <WarningProcuration v-if="!procurationActive && !procurationDeclined && procurationEnded && !procurationOnCheck">
    <p>Доверенность на Представителя компании истекла. Пожалуйста, загрузите новую доверенность или свяжитесь со службой поддержки.</p>
  </WarningProcuration>

  <WarningProcuration v-if="!procurationActive && !procurationDeclined && procurationEnded && procurationOnCheck">
    <p>
      Доверенность на Представителя компании истекла. Новая доверенность загружена и проверяется, функционал личного кабинета Заказчика
      ограничен.
    </p>
  </WarningProcuration>

  <WarningProcuration v-if="!procurationActive && !procurationDeclined && !procurationEnded && procurationOnCheck">
    <p>Новая доверенность загружена и проверяется, функционал личного кабинета Заказчика ограничен.</p>
  </WarningProcuration>

  <WarningProcuration
    v-if="!procurationActive && procurationDeclined"
    procurationDeclined
    :procurationNumber="procurationNumber"
    :procurationDate="procurationReleaseDate"
    :procurationDeclineReason="procurationDeclineReason"
  />

  <template v-if="!modalWereShown">
    <UploadProcuration
      v-if="
        (procurationActive && procurationExpiring) ||
        (!procurationActive && procurationOnCheck) ||
        (!procurationActive && !procurationOnCheck)
      "
      :procurationActive="procurationActive"
      :procurationOnCheck="procurationOnCheck"
      :procurationExpiring="procurationExpiring"
      :procurationEnded="procurationEnded"
      :procurationDaysLeft="procurationDaysLeft"
      :startDateNewProcuration="startDateNewProcuration"
      :showModal="showModal"
      @close="hideByUser"
    />
  </template>
</template>

<script>
import { API } from '@/utils/API'
import WarningProcuration from '@/components/Messages/WarningProcuration.vue'
import UploadProcuration from '@/components/Modals/UploadProcuration.vue'
import { compareDates } from '@/views/Orders/utils'
import { EProcurationStatus } from '@/types/api-values'
import { useCompanyStore } from '@/stores/company'
import { useUiStore } from '@/stores/ui'

export default {
  name: 'HeaderWarnings',
  components: {
    WarningProcuration,
    UploadProcuration
  },
  data() {
    return {
      uiStore: useUiStore(),
      showModal: false,
      procurationActive: false,
      procurationOnCheck: false,
      procurationExpiring: false,
      procurationEnded: false,
      procurationDeclined: false,
      procurationNumber: '',
      procurationDeclineReason: '',
      procurationReleaseDate: '',
      procurationDaysLeft: '',
      startDateNewProcuration: ''
    }
  },
  async created() {
    const companyStore = useCompanyStore()
    if (companyStore.activeCompany?.signer === 'representative') {
      companyStore.isProcurationEnded = true

      await this.getActiveProcurations()

      this.getAllProcuratoins()

      this.getExpiringProcurations()
    } else {
      this.procurationActive = true
      companyStore.isProcurationEnded = false
    }
  },
  methods: {
    hideByUser() {
      this.showModal = false
      this.uiStore.procurationModalShown = true
    },
    async getActiveProcurations() {
      const res = await this.axios.get(API.GET_ACTIVE_PROCURATIONS)

      if (res.data.procurations.length && res.data.representative_current_signer) {
        this.procurationActive = true
      } else {
        this.procurationActive = false
        this.showModal = true
      }
    },
    getAllProcuratoins() {
      // filter by status
      // API.GET_COMPANY_PROCURATIONS + '?status=active'
      this.axios
        .get(API.GET_COMPANY_PROCURATIONS + '?count=1000')
        .then((res) => {
          const results = res.data.results

          // No procurations
          if (!results?.length) {
            this.procurationActive = false
            this.showModal = true
            return
          }

          const activeProcurations = results.filter((procuration) => {
            const isActive = procuration.status === EProcurationStatus.active
            const days = compareDates(new Date(procuration.procuration_exp_date), new Date())
            const isNotFinished = days >= 0
            const isExpiring = days <= 30

            if (isExpiring && isNotFinished && isActive) {
              this.procurationExpiring = true
              if (!this.procurationDaysLeft) {
                this.procurationDaysLeft = days
              } else if (this.procurationDaysLeft < days) {
                this.procurationDaysLeft = days
              }
            }
            const daysFromStart = compareDates(new Date(), new Date(procuration.procuration_release_date)) > 0
            return daysFromStart && isNotFinished && isActive
          })

          if (activeProcurations.length) {
            const companyStore = useCompanyStore()

            this.procurationActive = true
            companyStore.isProcurationEnded = false
            return
          }

          const expiredProcurations = results.filter((p) => p.status === EProcurationStatus.expired)
          const onCheckProcurations = results.filter((p) => p.status === EProcurationStatus.check)
          const declinedProcurations = results.filter((p) => p.status === EProcurationStatus.declined)

          if (declinedProcurations.length) {
            declinedProcurations.forEach((procuration) => {
              this.procurationNumber = procuration.procuration_number
              this.procurationDeclineReason = procuration.decline_reason
              if (procuration.procuration_release_date) {
                const formattedProcurationReleaseDate = procuration.procuration_release_date.split('-')
                this.procurationReleaseDate = `${formattedProcurationReleaseDate[2]}.${formattedProcurationReleaseDate[1]}.${formattedProcurationReleaseDate[0]}`
              }
              this.procurationDeclined = true
            })
          }

          this.procurationEnded = !!expiredProcurations.length

          this.procurationOnCheck = !!onCheckProcurations.length

          onCheckProcurations.forEach((procuration) => {
            if (procuration.procuration_release_date) {
              const formattedStartDateNewProcuration = procuration.procuration_release_date.split('-')
              this.startDateNewProcuration = `${formattedStartDateNewProcuration[2]}.${formattedStartDateNewProcuration[1]}.${formattedStartDateNewProcuration[0]}`
            }
          })
        })
        .catch()
    },
    getExpiringProcurations() {
      this.axios
        .get(API.GET_EXPIRING_PROCURATIONS)
        .then((res) => {
          if (res.data.has_expiring) {
            res.data.procurations.forEach((procuration) => {
              this.axios
                .get(API.GET_PROCURATION(procuration))
                .then((res) => {
                  const status = res.data.status
                  if (status === EProcurationStatus.active) {
                    this.procurationExpiring = true
                    const days = compareDates(new Date(res.data.procuration_exp_date), new Date())
                    if (!this.procurationDaysLeft) {
                      this.procurationDaysLeft = days
                    } else if (this.procurationDaysLeft < days) {
                      this.procurationDaysLeft = days
                    }
                  }
                })
                .catch((e) => {
                  console.error(e)
                })
            })
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  },
  computed: {
    modalWereShown() {
      return this.uiStore.procurationModalShown
    }
  }
}
</script>

<style lang="postcss" scoped>
:deep(.modal) {
  @apply !gap-2 !max-w-[600px];
}

:deep(.modal .-content) {
  @apply !gap-0;
}
</style>
