
import { Options, Vue } from 'vue-class-component'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import { API } from '@/utils/API'
import Loader from '@/components/Loader/Loader.vue'
import { UTable } from 'unit-uikit'
import { formatName, formatTime } from '@/utils/helpers'

@Options({
  components: {
    UTable,
    EmptyPagePlaceholder,
    Loader
  },
  data() {
    return {
      loading: true,
      documents: [],
      documentsCount: 0,
      timer: null,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      }
    }
  },
  created() {
    this.axios
      .get(API.GET_DOCS_EXPORT + '?page_size=20')
      .then((res: { data: any }) => {
        this.documents = res.data.results
        this.scrollData.count = res.data.count
        this.loading = false
      })
      .catch((e: any) => {
        Sentry.captureException(e)
        this.loading = false
      })
  },
  methods: {
    getDocPeriod(doc: any) {
      const start = doc.period_begin && doc.period_begin !== '1970-01-01' ? doc.period_begin : ''
      const end = doc.period_end ? doc.period_end : ''
      const delimiter = start && end ? ' - ' :
        start && !end ? '' :
        !start && end ? 'До ' : ''
      return `${start}${delimiter}${end}`;
    },
    async loadDocuments() {
      if (this.documents.length < this.scrollData.count) {
        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}`
        const response = await this.axios.get(API.GET_DOCS_EXPORT + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.scrollData.count = data.count || 0
          const result = data.results
          this.documents.push(...result)
        }
      }
    },
    formatName,
    formatTime,
    formatDate(date: any) {
      if (date) {
        return moment(new Date(date)).format('DD.MM.YY')
      }
      return ''
    },
    updateLink(doc: any, index: number) {
      doc.loading = true
      const id = doc.id
      const size = this.scrollData.size
      const page = Math.floor(index / size) + 1

      const filePulling = (doc: any, page: number, size: number) => {
        return setTimeout(() => {
          const queryString = `?page_size=${size}&page=${page}`
          doc.timer = null
          this.axios
            .get(API.GET_DOCS_EXPORT + queryString)
            .then((res: { data: any }) => {
              // update first 10 documents
              const { results } = res.data
              const _doc = results.find((item: any) => {
                return item.id === id
              })
              if (_doc.file && _doc.status === 'available') {
                const link = _doc.file
                doc.file = link.replace('http://', 'https://')
                const a = document.createElement('a')
                a.href = doc.file
                a.setAttribute('download', doc.name + '.zip')
                document.body.appendChild(a)
                a.click()
                doc.loading = false
              } else {
                doc.timer = filePulling(doc, page, size)
              }
            })
            .catch((e: any) => {
              Sentry.captureException(e)
              doc.loading = false
            })
        }, 10 * 1000)
      }

      if (id && doc.status === 'available') {
        this.axios
          .post(
            API.RECREATE_DOCS_LINK(),
            {
              unload_id: id
            }
          )
          .then(() => {
            // start long pulling
            doc.timer = filePulling(doc, page, size)
          })
          .catch((e: any) => {
            Sentry.captureException(e)
          })
      } else if (id) {
        doc.timer = filePulling(doc, page, size)
      }
    },
    getSecureLink(link: string) {
      if (link) {
        return link.replace(/^(http:\/\/)/, 'https://')
      }
      return ''
    }
  }
})
export default class DocHistory extends Vue {}
