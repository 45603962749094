import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99b0aa20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-center border border-black border-solid rounded items-center bg-white label-wrapper" }
const _hoisted_2 = { class: "flex-grow overflow-hidden" }
const _hoisted_3 = { class: "input-title absolute" }
const _hoisted_4 = { style: {"font-size":"13px","line-height":"normal","padding-top":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1),
      _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.file.name), 1)
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "file-remove",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeFile && _ctx.removeFile(...args)), ["prevent"]))
    }, "+")
  ]))
}