
import NavBar from '@/components/Navigation/NavBar.vue'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    NavBar
  },
  name: 'MainPage'
})
export default class MainPage extends Vue {}
