
import { Options, Vue } from 'vue-class-component'
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import PersonalListContent from './PersonalListContent.vue'
import { UButton } from 'unit-uikit'

@Options({
  name: 'personal',
  components: {
    MainPageHeader,
    UButton,
    PersonalListContent
  }
})
export default class Personal extends Vue {}
