
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'FileItemView',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    label: { type: String },
    file: { type: File }
  },
  methods: {
    removeFile() {
      const files = this.$attrs.modelValue.filter((item: File) => {
        return item !== this.file
      })
      this.$emit('update:modelValue', files)
    }
  }
})
export default class FileItemView extends Vue {}
