
import { Options, Vue } from 'vue-class-component'
import moment from 'moment'
import { UButton } from 'unit-uikit'

@Options({
  components: {
    UButton
  },
  props: {
    results: Array,
    closeAction: Function,
    returnToWorkAction: Function
  },
  data() {
    return {
      activeFileIndex: 0
    }
  },
  methods: {
    getPreviewFileUrl(file: string) {
      if (file) {
        const URL_CLEAR = process.env.VUE_APP_URL
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    },
    formatDate(date: any) {
      if (date) {
        return moment(new Date(date)).format('DD.MM.YY')
      }
      return ''
    }
  },
  computed: {
    allFiles() {
      return (this.results && this.results[0] && this.results[0].documents) || []
    },
    thereAreFiles() {
      const files = this.allFiles
      return !!(files && files.length)
    },
    getFileUrl() {
      const files = this.allFiles
      const file = files && files[this.activeFileIndex] && files[this.activeFileIndex].file
      if (file) {
        const URL_CLEAR = process.env.VUE_APP_URL
        return `${URL_CLEAR}/media/${file.replace('media/', '')}`
      }
      return ''
    }
  }
})
export default class FilesResultsItem extends Vue {}
