
import { Options, Vue } from 'vue-class-component'
import moment from 'moment'
import { formatPrice, getQiwiErrCode, formatToServerDate, formatName } from '@/utils/helpers'
import { API } from '@/utils/API'
import ErrorTooltip from '@/components/Tooltips/ErrorTooltip.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import Loader from '@/components/Loader/Loader.vue'
import { TRANSACTION_STATUS, TRANSACTION_COLORS } from '@/utils/consts'
import { ETransactionsStatus } from '@/types/api-values'
import debounce from '@/utils/debounce'
import { UTable, UInput, UISelect, UDateRange } from 'unit-uikit'

@Options({
  name: 'FinanceHistory',
  components: {
    UDateRange,
    EmptyPagePlaceholder,
    Loader,
    ErrorTooltip,
    UTable,
    UInput,
    UISelect
  },
  data() {
    return {
      transactions: [],
      loading: true,
      selectedStatus: '',
      searchFirstName: '',
      searchLastName: '',
      statusOptions: [
        {
          id: '',
          name: 'Все'
        },
        {
          id: ETransactionsStatus.awaiting,
          name: TRANSACTION_STATUS[ETransactionsStatus.awaiting]
        },
        {
          id: ETransactionsStatus.not_signed,
          name: TRANSACTION_STATUS[ETransactionsStatus.not_signed]
        },
        {
          id: ETransactionsStatus.paid,
          name: TRANSACTION_STATUS[ETransactionsStatus.paid]
        },
        {
          id: ETransactionsStatus.sent,
          name: TRANSACTION_STATUS[ETransactionsStatus.sent]
        },
        {
          id: ETransactionsStatus.cancelled,
          name: TRANSACTION_STATUS[ETransactionsStatus.cancelled]
        },
        {
          id: ETransactionsStatus.error,
          name: TRANSACTION_STATUS[ETransactionsStatus.error]
        }
      ],
      filterDates: {
        start_date: null,
        finish_date: null
      },
      ETransactionsStatus,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      },
      debounceLoadOrdersWithParams: null
    }
  },
  methods: {
    formatToServerDate,
    getQiwiErrCode,
    loadOrdersWithParams() {
      this.scrollData.requestCount = 0
      this.scrollData.page = 0
      this.transactions = []
      this.loadHistory()
    },
    async loadHistory() {
      if (this.transactions.length < this.scrollData.count) {
        let queryParams = ''
        if (this.searchFirstName) {
          queryParams += `&first_name=${this.searchFirstName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (this.searchLastName) {
          queryParams += `&last_name=${this.searchLastName}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (this.selectedStatus) {
          queryParams += `&status=${this.selectedStatus}`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        const startDate = this.filterDates.start_date ? new Date(this.filterDates.start_date) : null
        const finishDate = this.filterDates.finish_date ? new Date(this.filterDates.finish_date) : null
        if (startDate) {
          queryParams += `&start_date=${formatToServerDate(startDate)}T00:00:00`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }
        if (finishDate) {
          queryParams += `&end_date=${formatToServerDate(finishDate)}T23:59:59.99`

          if (this.transactions.length && !(this.transactions.length < this.scrollData.requestCount)) {
            return
          }
        }

        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}` + queryParams
        const response = await this.axios.get(API.GET_TRANSACTIONS_HISTORY + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.scrollData.requestCount = data.count
          const transactions = data.results
          this.transactions.push(...transactions)
        }
      }
    },
    formatName,
    formatDate(date: any) {
      return moment(new Date(date)).format('DD.MM.YY HH:mm')
    },
    formatPrice(price: number | string) {
      return formatPrice(price)
    },
    formatStatus(status: ETransactionsStatus) {
      return status && TRANSACTION_STATUS[status] ? TRANSACTION_STATUS[status] : ''
    },
    getStatusColor(status: ETransactionsStatus) {
      return status && TRANSACTION_COLORS[status] ? TRANSACTION_COLORS[status] : '#27BBDC'
    }
  },
  created() {
    this.axios
      .get(API.GET_TRANSACTIONS_HISTORY + `?page=${this.scrollData.page}&page_size=${this.scrollData.size}`)
      .then((response: { data: any }) => {
        this.scrollData.count = response.data.count
        this.transactions = response.data.results || []
        this.loading = false
      })
      .catch((error: any) => {
        console.error(error)
        this.loading = false
      })
  },
  watch: {
    'filterDates.start_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    'filterDates.finish_date'() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchFirstName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    searchLastName() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    },
    selectedStatus() {
      if (this.debounceLoadOrdersWithParams) {
        this.debounceLoadOrdersWithParams()
      } else {
        this.debounceLoadOrdersWithParams = debounce(() => {
          this.loadOrdersWithParams()
        })
        this.debounceLoadOrdersWithParams()
      }
    }
  }
})
export default class FinanceHistory extends Vue {}
