
import { Options, Vue } from 'vue-class-component'
import { UButton } from 'unit-uikit'

@Options({
  components: {
    UButton
  },
  data() {
    return {
      isMobileDevice: false,
      showMac: false
    }
  },
  methods: {
    goToLogin(userType: string) {
      if (userType === 'worker') {
        if (this.clientWidth > 600) {
          this.isMobileDevice = true
        }
      } else {
        if (userType === 'customer') {
          if (this.clientWidth > 600) {
            this.$router.push('/login')
          } else {
            this.isMobileDevice = true
          }
        }
      }
    }
  },
  computed: {
    getMainTitle() {
      return this.isMobileDevice ? 'Сайт для самозанятых доступен только на мобильном устройстве' : 'Выберите тип аккаунта'
    },
    clientWidth() {
      return document.documentElement.clientWidth
    }
  }
})
export default class Landing extends Vue {}
