
import { Options, Vue } from 'vue-class-component'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { API } from '@/utils/API'
import Loader from '@/components/Loader/Loader.vue'
import EmptyPagePlaceholder from '@/components/EmptyPagePlaceholder/EmptyPagePlaceholder.vue'
import { UTable } from 'unit-uikit'
import { formatName, formatTime } from '@/utils/helpers'

@Options({
  components: {
    UTable,
    Loader,
    EmptyPagePlaceholder
  },
  data() {
    return {
      loading: true,
      personal: [],
      personalCount: 0,
      timer: null,
      scrollData: {
        count: 0,
        requestCount: 0,
        size: 20,
        page: 1
      }
    }
  },
  created() {
    this.getCompanyStaff()
  },
  methods: {
    formatTime,
    formatName,
    getCompanyStaff() {
      this.axios
        .get(API.GET_COMPANY_STAFF + '?page_size=20')
        .then((res: { data: any }) => {
          this.personal = res.data
          this.scrollData.count = res.data.length
          this.loading = false
        })
        .catch((e: any) => {
          this.loading = false
          Sentry.captureException(e)
        })
    },
    async findPersonal() {
      if (this.personal.length < this.scrollData.count) {
        this.scrollData.page++
        const queryString = `?page=${this.scrollData.page}&page_size=${this.scrollData.size}`
        const response = await this.axios.get(API.GET_DOCS_EXPORT + queryString)

        const data = response.data
        if (data.results && data.count) {
          this.scrollData.count = data.count
          const result = data.results
          this.personal.push(...result)
        }
      }
    },
    formatStatus(worker: any) {
      return worker.verified ? 'Верифицирован' : 'Проходит верификацию'
    },
    formatSignStatus(worker: any) {
      return worker.is_enable ? '' : 'Отключен от подписания'
    },
    formatDate(date: any) {
      if (date) {
        return moment(new Date(date)).format('DD.MM.YY')
      }
      return ''
    },
    closeEditMenu() {
      this.personal = this.personal.map((item: any) => {
        item.showEditMenu = false
        return item
      })
    },
    openEditMenu(id: string | number) {
      this.personal = this.personal.map((item: any) => {
        const itemId = item.id
        if (id + '' === itemId + '') {
          item.showEditMenu = !item.showEditMenu
        } else {
          item.showEditMenu = false
        }
        return item
      })
    },
    enableSign(id: string | number) {
      this.axios
        .patch(
          API.DISABLED_COMPANY_STAFF(id),
          {
            is_enable: true
          }
        )
        .then(() => {
          this.getCompanyStaff()
        })
        .catch((e: any) => {
          console.error(e)
        })
    },
    disableSign(id: string | number) {
      this.axios
        .patch(
          API.DISABLED_COMPANY_STAFF(id),
          {
            is_enable: false
          }
        )
        .then(() => {
          this.getCompanyStaff()
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }
})
export default class PersonalListContent extends Vue {}
