import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ee04306"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "worker-description" }
const _hoisted_2 = { class: "text-base font-medium relative" }
const _hoisted_3 = { class: "citizenship" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorTooltip = _resolveComponent("ErrorTooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createElementVNode("span", {
        class: _normalizeClass({ 'text-error': _ctx.isDisabled, 'opacity-30': _ctx.isDisabledAdd })
      }, _toDisplayString(_ctx.getWorkerName(_ctx.worker)), 3),
      (_ctx.isDisabled)
        ? (_openBlock(), _createBlock(_component_ErrorTooltip, {
            key: 0,
            class: "err-tooltip-worker",
            tooltip: `С этим исполнителем будет заключен договор от ${_ctx.startDate}, так как у него нет действующего договора. Пожалуйста, воспользуйтесь разделом Уведомления МВД для подачи уведомления 7 в контролирующий орган и отметьте статус подачи “Подано”. Без этого невозможно создать акт.`
          }, null, 8, ["tooltip"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("p", {
      class: _normalizeClass([{ 'opacity-30': _ctx.isDisabledAdd }, "heading-gray-3"])
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["mr-1 text-sm leading-5 text-success", {
          'text-error': _ctx.worker && _ctx.worker.personaldata && _ctx.worker.personaldata['is_self_employed'] !== false
        }])
      }, _toDisplayString(_ctx.getWorkerStatus(_ctx.worker && _ctx.worker.personaldata && _ctx.worker.personaldata['is_self_employed'])), 3),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getCitizenship(_ctx.worker.personaldata)), 1)
    ], 2)
  ]))
}