
import { Options, Vue } from 'vue-class-component'
import { API } from '@/utils/API'
import useValidate from '@vuelidate/core'
import { required, maxLength, numeric } from '@vuelidate/validators'
import { UInput, UButton, UModal, UDateRange } from 'unit-uikit'
import { useAuthStore } from '@/stores/auth'

@Options({
  components: {
    UInput,
    UButton,
    UModal,
    UDateRange
  },
  emits: ['updateCounter'],
  data() {
    return {
      v$: useValidate(),
      ready: false,
      loading: false,
      showModal: false,
      trustedPerson: {
        procuration_number: '',
        procuration_release_date: '',
        procuration_exp_date: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        passport_series: '',
        passport_number: '',
        passport_whom_given: '',
        date_of_issue: '',
        position: '',
        company: ''
      },
      date_of_issue_error: false
    }
  },
  validations() {
    return {
      trustedPerson: {
        procuration_number: { required },
        procuration_release_date: { required },
        procuration_exp_date: { required },
        last_name: { required, maxLength: maxLength(150) },
        first_name: { required, maxLength: maxLength(150) },
        middle_name: { maxLength: maxLength(150) },
        passport_series: { required, numeric },
        passport_number: { required, numeric },
        passport_whom_given: { required, maxLength: maxLength(150) },
        date_of_issue: { required },
        position: { required }
      }
    }
  },
  methods: {
    saveTrustedPersonData() {
      this.v$.$validate()

      if (this.date_of_issue && this.date_of_issue.length < 10) {
        this.date_of_issue_error = true
        return
      } else {
        this.date_of_issue_error = false
      }

      if (!this.v$.$error) {
        this.loading = true
        const authStore = useAuthStore()

        const formatedProcurationReleaseDate =
          typeof this.trustedPerson?.procuration_release_date === 'object'
            ? this.trustedPerson?.procuration_release_date.toLocaleDateString('ru-RU').split('.')
            : this.trustedPerson?.procuration_release_date.split('-')
        const formatedProcurationExpDate =
          typeof this.trustedPerson?.procuration_exp_date === 'object'
            ? this.trustedPerson?.procuration_exp_date.toLocaleDateString('ru-RU').split('.')
            : this.trustedPerson?.procuration_exp_date.split('-')
        const formatedDateOfIssue = this.trustedPerson?.date_of_issue.split('.')

        const data = {
          procuration_number: this.trustedPerson?.procuration_number,
          procuration_release_date:
            typeof this.trustedPerson?.procuration_release_date === 'object'
              ? `${formatedProcurationReleaseDate[2]}-${formatedProcurationReleaseDate[1]}-${formatedProcurationReleaseDate[0]}`
              : `${formatedProcurationReleaseDate[0]}-${formatedProcurationReleaseDate[1]}-${formatedProcurationReleaseDate[2]}`,
          procuration_exp_date:
            typeof this.trustedPerson?.procuration_exp_date === 'object'
              ? `${formatedProcurationExpDate[2]}-${formatedProcurationExpDate[1]}-${formatedProcurationExpDate[0]}`
              : `${formatedProcurationExpDate[0]}-${formatedProcurationExpDate[1]}-${formatedProcurationExpDate[2]}`,
          last_name: this.trustedPerson?.last_name,
          first_name: this.trustedPerson?.first_name,
          middle_name: this.trustedPerson?.middle_name,
          passport_series: this.trustedPerson?.passport_series,
          passport_number: this.trustedPerson?.passport_number,
          passport_whom_given: this.trustedPerson?.passport_whom_given,
          date_of_issue: `${formatedDateOfIssue[2]}-${formatedDateOfIssue[1]}-${formatedDateOfIssue[0]}`,
          position: this.trustedPerson?.position,
          company: authStore.payload?.company_id
        }

        if (this.$route.name.includes('add')) {
          this.loading = true
          this.axios
            .post(API.GET_TRUSTED_PERSONS, data)
            .then((response: any) => {
              this.showModal = true
              this.loading = false
            })
            .catch((error: any) => {
              this.loading = false
              console.error(error)
            })
        } else {
          this.loading = true
          this.axios
            .patch(API.TRUSTED_PERSON(this.$route.params.id), data)
            .then((response: any) => {
              this.loading = false
              this.$router.push('/omvd/trustedpersons')
            })
            .catch((error: any) => {
              this.loading = false
              console.error(error)
            })
        }
      }
    }
  },
  created() {
    if (this.$route.name.includes('edit')) {
      this.axios
        .get(API.GET_TRUSTED_PERSON(this.$route.params.id))
        .then((response: any) => {
          const formatedDateOfIssue = response.data.date_of_issue.split('-')

          this.trustedPerson.procuration_number = response.data.procuration_number
          this.trustedPerson.procuration_release_date = response.data.procuration_release_date
            ? new Date(response.data.procuration_release_date)
            : null
          this.trustedPerson.procuration_exp_date = response.data.procuration_exp_date ? new Date(response.data.procuration_exp_date) : null
          this.trustedPerson.last_name = response.data.last_name
          this.trustedPerson.first_name = response.data.first_name
          this.trustedPerson.middle_name = response.data.middle_name
          this.trustedPerson.passport_series = response.data.passport_series
          this.trustedPerson.passport_number = response.data.passport_number
          this.trustedPerson.passport_whom_given = response.data.passport_whom_given
          this.trustedPerson.date_of_issue = `${formatedDateOfIssue[2]}.${formatedDateOfIssue[1]}.${formatedDateOfIssue[0]}`
          this.trustedPerson.position = response.data.position

          this.ready = true
        })
        .catch((error: any) => {
          console.error(error)
          this.ready = true
        })
    } else {
      this.ready = true
    }
  },
  computed: {
    isEditTrustedPerson() {
      if (this.$route.name.includes('add')) {
        return false
      } else {
        return true
      }
    }
  }
})
export default class OmvdTrustedPersonsAdd extends Vue {}
