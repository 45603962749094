
import { Options, Vue } from 'vue-class-component'
import COUNTRIES from '@/utils/Countries'
import ErrorTooltip from '@/components/Tooltips/ErrorTooltip.vue'

@Options({
  components: { ErrorTooltip },
  props: {
    worker: Object,
    startDate: String,
    isDisabledAdd: Boolean
  },
  methods: {
    getWorkerName(worker: any) {
      return `${worker.last_name} ${worker.first_name} ${worker.middle_name || ''}`
    },
    getWorkerStatus(status: any) {
      if (status === true) {
        return 'Статус самозанятого'
      }
      return 'Нет статуса самозанятого'
    },
    getCitizenship(personaldata: any) {
      if (!personaldata) {
        return ''
      }
      if (!personaldata.citizenship) {
        return ''
      }
      return COUNTRIES[personaldata.citizenship]
    }
  },
  computed: {
    isDisabled() {
      const citizenship = this.getCitizenship(this.worker.personaldata)
      const noAggrement = !this.worker?.agreement?.length

      return noAggrement && !['РФ', 'Республика Беларусь'].includes(citizenship)
    }
  }
})
export default class WorkerTableItem extends Vue {}
