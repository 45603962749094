
import MainPageHeader from '@/components/MainPageHeader/MainPageHeader.vue'
import { Options, Vue } from 'vue-class-component'
import CreateNotifications from '@/components/Modals/CreateNotifications.vue'
import DownloadNotifications from '@/components/Modals/DownloadNotifications.vue'
import { UButton, UTabs, UModal } from 'unit-uikit'
import { API } from '@/utils/API'
import { useCompanyStore } from '@/stores/company'
import { useUiStore } from '@/stores/ui'

@Options({
  name: 'projects',
  data() {
    return {
      uiStore: useUiStore(),
      title: 'Документы',
      titleAddTrustedPerson: 'Создание нового доверенного лица',
      titleEditTrustedPerson: 'Изменение данных доверенного лица',
      showDownloadNotificationsPopup: false,
      showNotificationsPopup: false,
      successDocsCreated: false,
      successCreated: false,
      failedcreate: false,
      badge: 0,
      tabs: [
        {
          routeName: 'omvd.all',
          label: 'Уведомления МВД'
        },
        {
          routeName: 'omvd.notifications',
          label: 'Уведомления о расторжении',
          badge: 0
        },
        {
          routeName: 'omvd.history',
          label: 'Архив уведомлений'
        },
        {
          routeName: 'omvd.trustedpersons',
          label: 'Доверенные лица'
        }
      ]
    }
  },
  components: {
    MainPageHeader,
    UButton,
    CreateNotifications,
    UModal,
    DownloadNotifications,
    UTabs
  },
  methods: {
    showNotificationsModal() {
      this.showDownloadNotificationsPopup = true
    },
    createNotificationError() {
      this.showNotificationsPopup = false
      this.failedcreate = true
    },
    createNotificationSuccess() {
      this.showNotificationsPopup = false
      this.successCreated = true
    },
    createNotificationUnloadError() {
      this.showDownloadNotificationsPopup = false
      this.failedcreate = true
    },
    createNotificationUnloadSuccess() {
      this.showDownloadNotificationsPopup = false
      this.successDocsCreated = true
    },
    createNotifications() {
      this.showNotificationsPopup = true
    },
    updateCounter() {
      const queryString =
        '?count=1&page=1&app_7=filed&app_8=exists,signed,not_signed,active,finished,terminated,converting_error,null&order=exp_date'
      this.axios
        .get(API.GET_DOCUMENTS_NOTIFICATIONS + queryString)
        .then((res: any) => {
          this.badge = res.data.expiring
          this.tabs[1].badge = this.badge
        })
        .catch()
    }
  },
  computed: {
    isProcurationEnded() {
      const companyStore = useCompanyStore()
      return companyStore.isProcurationEnded
    },
    checkedNotifications() {
      return this.uiStore.checkedNotifications || []
    },
    isAddOrEditTrustedPerson() {
      if (this.$route.name.includes('add') || this.$route.name.includes('edit')) {
        return true
      } else {
        return false
      }
    },
    getTitle() {
      if (this.$route.name.includes('add')) {
        return this.titleAddTrustedPerson
      } else if (this.$route.name.includes('edit')) {
        return this.titleEditTrustedPerson
      } else {
        return this.title
      }
    }
  },
  created() {
    this.updateCounter()
  }
})
export default class Omvd extends Vue {}
